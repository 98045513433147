import React, { useEffect, useMemo, useState } from 'react';
import { Button, Typography, Space } from 'antd';
import { InfoCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Loader } from 'ui/Loader/Loader';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_FORMAT, REGEX_REPLACE_GMT } from 'utils/helpers/dates';
import { useSelectData } from '../SmartSelectField/utils';

import './SlotsPicker.scss';

interface SlotsPickerProps {
    value: any;
    onChange: (newValue: any) => void;
    meta: string;
    filters?: string;
}

export const SlotsPicker: React.FC<SlotsPickerProps> = ({
    value,
    onChange,
    meta,
    filters = ''
}) => {
    const { t } = useTranslation();

    const [currentDay, setCurrentDay] = useState(0); // 0 for today, 1 for tomorrow, etc.
    // const [selectedSlot, setSelectedSlot] = useState(null);

    const { fetchData, dataSource, metaSource, modalFields, modalTitle, isLoading, isError } =
        useSelectData({
            meta,
            filters
        });

    // console.log(dataSource, metaSource, modalFields);

    useEffect(() => {
        fetchData();
    }, [filters, meta]);

    // console.log(value);

    // const slots = useMemo(() => {
    //     return dataSource.map((item) => ({data: item, }));
    // }, []);

    // const days = ['Сегодня', 'Завтра', 'Послезавтра'];
    const days = useMemo(() => {
        if (!dataSource?.length) return [{ value: dayjs(), label: t('today') }];

        const firstSlot = dataSource[0];
        const lastSlot = dataSource.at(-1);

        const firstDate = dayjs(firstSlot?.SlotFromAt);
        // const lastDateDate = dayjs(lastSlot?.SlotToAt);
        const lastDateDate = dayjs(lastSlot?.SlotFromAt);

        const diff = lastDateDate.diff(firstDate, 'days');

        // console.log(diff);

        const result = [];

        for (let i = 0; i <= diff; i++) {
            const value = firstDate.add(i, 'days');
            const diffFromNow = dayjs().diff(value, 'days');

            const isToday = diffFromNow === 0;
            const isYesterday = diffFromNow === 1;
            const isTomorrow = diffFromNow === -1;

            const label = isToday
                ? t('today')
                : isTomorrow
                ? t('tomorrow')
                : isYesterday
                ? t('yesterday')
                : value.format(DATE_FORMAT);

            result.push({ value, label });
        }

        return result;
    }, [dataSource, t]);
    // const slots = ['15:30', '16:30', '17:30'];

    const handlePrevDay = () => {
        if (currentDay > 0) {
            setCurrentDay(currentDay - 1);
            // setSelectedSlot(null); // Reset selected slot when day changes
        }
    };

    const handleNextDay = () => {
        if (currentDay < days.length - 1) {
            setCurrentDay(currentDay + 1);
            // setSelectedSlot(null); // Reset selected slot when day changes
        }
    };

    // console.log(currentDay);

    return (
        <Loader
            wrapperClassName="slots_picker__loader"
            status={isLoading || isError}
            style={isError ? { color: 'rgba(0,0,0,.85)' } : undefined}
            customTip={isError ? (t('wait_select') as string) : undefined}
            customIndicator={isError ? <InfoCircleOutlined /> : undefined}
        >
            <div
                style={{
                    textAlign: 'center',
                    padding: '8px',
                    margin: '0 auto',
                    maxWidth: '100%',
                    overflowX: 'auto'
                }}
            >
                <Space align="center" size="middle">
                    <Button
                        size="small"
                        shape="circle"
                        icon={<LeftOutlined />}
                        onClick={handlePrevDay}
                        disabled={currentDay === 0}
                    />

                    <Typography.Text>
                        {days[currentDay].label} (
                        {
                            ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'][
                                (new Date().getDay() - 1 + currentDay) % 7
                            ]
                        }
                        )
                    </Typography.Text>

                    <Button
                        size="small"
                        shape="circle"
                        icon={<RightOutlined />}
                        onClick={handleNextDay}
                        disabled={currentDay === days.length - 1}
                    />
                </Space>

                <div style={{ marginTop: '16px' }}>
                    <Space style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        {dataSource
                            .filter(
                                (slot) =>
                                    dayjs(slot.SlotFromAt).date() === days[currentDay].value.date()
                            )
                            .map((slot) => {
                                const hour = dayjs(
                                    slot.SlotFromAt.replace(REGEX_REPLACE_GMT, '').replace('Z', '')
                                ).hour();
                                const minute = dayjs(
                                    slot.SlotFromAt.replace(REGEX_REPLACE_GMT, '').replace('Z', '')
                                ).minute();

                                const isHourLessThenX = hour < 10;
                                const isMinuteLessThenX = minute < 10;

                                // console.log(slot);

                                return (
                                    <Button
                                        key={slot.Id}
                                        type={value?.Id === slot.Id ? 'primary' : 'default'}
                                        onClick={() => onChange(slot)}
                                    >
                                        {/* {slot.DisplayName?.[language]} */}
                                        {`${isHourLessThenX ? `0${hour}` : hour}:${
                                            isMinuteLessThenX ? `0${minute}` : minute
                                        }`}
                                    </Button>
                                );
                            })}
                    </Space>
                </div>
            </div>
        </Loader>
    );
};
