import { memo } from 'react';
import KeepAlive from 'react-activation';
import { useLocation, useParams } from 'react-router-dom';
import { NotesPage } from 'smart/modules';

export const KeepAlivedNotesPage = memo<{ rootMeta: string }>(({ rootMeta }) => {
    const { pathname, state } = useLocation();
    const { meta } = useParams();

    console.log(state);

    return (
        <KeepAlive
            name={`notesPage_${pathname}`}
            key={`notesPage_${pathname}`}
            cahceKey={`notesPage_${pathname}`}
        >
            <NotesPage rootMeta={rootMeta} meta={meta ?? state?.meta} />
        </KeepAlive>
    );
});
