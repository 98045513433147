import {
    BellOutlined,
    ClearOutlined,
    InfoCircleOutlined,
    LogoutOutlined,
    SearchOutlined,
    UserOutlined
} from '@ant-design/icons';
import { User } from '@supabase/supabase-js';
import { Badge, Button, Flex, Layout, Popover, Space } from 'antd';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAsync, useMedia, useUpdateEffect } from 'react-use';

// import { client } from 'modules/supabase/contexts/SupabaseContext/ApiContext';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import { useUserData } from 'modules/client/useAuthUser';
import { Meta } from 'modules/services/backend-api/generated_info';
import { routeStore, springboardStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';
import { HelpIcon } from 'smart/ui';
import { ButtonWithTooltips } from 'ui';

// import { Changelog } from '../Changelog/Changelog';
import { AppSearch } from './components';
import { fnv1aHash } from '../../../../../smart/utils';
import { useHandlerRun } from '../../../../../smart/utils/hooks';
import './Header.scss';
import { Changelog } from '../Changelog/Changelog';

interface HeaderProps extends React.PropsWithChildren {
    text?: string;
    toggleMenuButton?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    style?: React.CSSProperties;
    showUserProfile?: boolean;
    user: User | null;
    setUser: (user: User | null) => void;
    numNotifications: number;
}

export const Header = observer<HeaderProps>(
    ({
        toggleMenuButton,
        breadcrumbs,
        children,
        style,
        user,
        setUser,
        showUserProfile = true,
        numNotifications
    }) => {
        const { data, info, isPowerUser, loading: userLoading } = useUserData();
        const { t, i18n } = useTranslation();
        const navigate = useStoreNavigate();
        const { run } = useHandlerRun();
        // const { isPowerUser } = useSupabaseUser();

        // console.log('user data:', data);

        const showLanguageSwitcher = metaStore.meta.get('all')?.params?.SHOW_LANGUAGE_SWITCHER;

        const infos = useAsync(async () => metaStore.getInfos() as Promise<Meta[]>, []);

        const [faq, setFaq] = useState('');
        useUpdateEffect(() => {
            const value = infos.value;
            if (!infos.loading && value) {
                const filtered = value.filter(
                    (info) => !info?.RootMeta_Code && info?.Description?.[i18n.language]
                );

                const result = filtered.reduce((acc, info) => {
                    return `${acc}
    
                        ## ${info.PluralName?.[i18n.language]}
                            ${info.Description[i18n.language]}
                    `;
                }, `# ${t('faq')}`);

                setFaq(result);
            }
        }, [infos.loading, infos.value]);

        // const { value: showLanguageSwitcher } = useAsync(async () => {
        //     const showLanguageSwitcher = metaStore.meta.get('all')?.params?.SHOW_LANGUAGE_SWITCHER;

        //     if (showLanguageSwitcher) return showLanguageSwitcher;

        //     return metaStore.getParam({
        //         param_name: 'SHOW_LANGUAGE_SWITCHER',
        //         default_value: false
        //     });
        // }, []);

        const isSmallTablet = useMedia('(max-width: 620px)');
        const isBigMobile = useMedia('(max-width: 480px)');

        // const [isMobileSearchOpen, setIsMobileSearchOpen] = useBoolean(false);

        const handleCheckProfile = () => {
            // console.log(data?.Name);
            // navigate(`/settings/users/${data?.Id}`, {
            navigate(`/profile`, {
                state: {
                    data,
                    pageTitle: `${data?.Name?.[i18n.language === 'tech' ? 'en' : i18n.language]} (${
                        info?.SingularName?.[i18n.language === 'tech' ? 'en' : i18n.language]
                    })`,
                    cacheKey: fnv1aHash(`InfoProfiles_${data?.Id}_view`)
                }
            });
        };

        const handleLogout = async () => {
            setUser(null);
            routeStore.clear();
            metaStore.clear();
            springboardStore.reset();
            await metaStore?.api?.client?.signOut();
            // await supabaseClient.auth.signOut();
        };

        if (!user) return null;

        const userName =
            data?.Name?.[i18n.language === 'tech' ? 'en' : i18n.language] || data?.Email;

        return (
            <Layout.Header className="header" style={style}>
                <div className="header__container">
                    <div className="header__left">
                        {toggleMenuButton}

                        {!isBigMobile && <AppSearch />}

                        {breadcrumbs && <div className="header__breadcrumbs">{breadcrumbs}</div>}
                    </div>
                    <div className="header__content">{children}</div>

                    <Flex gap={5} align="center" justify="center" className="header__right">
                        <Space.Compact>
                            {isBigMobile && (
                                <Popover content={<AppSearch />}>
                                    <Button
                                        size={isBigMobile ? 'large' : 'middle'}
                                        icon={<SearchOutlined />}
                                        type="text"
                                        // onClick={() => setIsMobileSearchOpen(true)}
                                    />
                                </Popover>
                            )}
                            {!isBigMobile && showUserProfile && (
                                <ButtonWithTooltips
                                    id="profile"
                                    tooltipTitle={t('profile')}
                                    type="text"
                                    className="header__profile"
                                    icon={<UserOutlined />}
                                    onClick={handleCheckProfile}
                                    loading={userLoading}
                                    size={isBigMobile ? 'large' : 'middle'}
                                >
                                    {!isSmallTablet && userName}
                                </ButtonWithTooltips>
                            )}
                            {isPowerUser && (
                                <ButtonWithTooltips
                                    type={'text'}
                                    icon={<ClearOutlined />}
                                    size={isBigMobile ? 'large' : 'middle'}
                                    loading={userLoading}
                                    onClick={async () => {
                                        // routeStore.clear();
                                        // metaStore.clear();
                                        // navigate({ pathname, search }, { state }, { replace: true });
                                        //
                                        // await metaStore.getRoutes();
                                        // await Promise.all([
                                        //     metaStore.getMenu(),
                                        //     metaStore.makeSelect({ meta: 'InfoMeta' }),
                                        //     metaStore.getInfo('DocOrders'),
                                        //     metaStore.getInfo('DocTrips'),
                                        //     metaStore.getInfo('InfoObjectProperties')
                                        // ]);

                                        await run({
                                            meta: 'InfoMeta',
                                            args: {},
                                            Action_Id: 'e5911d48-8705-11ef-82aa-000000cdd23e',
                                            handler: 'ClearAllRedisCaches',
                                            ids: []
                                        });

                                        window.location.reload();
                                    }}
                                />
                            )}

                            <LanguageSwitcher
                                languageCode={data?.Language?.Code}
                                visible={!!showLanguageSwitcher}
                            />
                            <Badge
                                styles={{
                                    indicator: isBigMobile
                                        ? { top: 14, insetInlineEnd: 14 }
                                        : { top: 10, insetInlineEnd: 10 }
                                }}
                                dot={numNotifications > 0}
                            >
                                <ButtonWithTooltips
                                    size={isBigMobile ? 'large' : 'middle'}
                                    id="notifications"
                                    tooltipTitle={t('notifications')}
                                    type="text"
                                    icon={<BellOutlined style={{ fontSize: 18 }} />}
                                    onClick={() => {
                                        const route = metaStore.meta
                                            .get('all')
                                            ?.routes?.find(
                                                (r) =>
                                                    r.meta === 'InfoNotifications' &&
                                                    r.path.includes('settings') &&
                                                    r.path.includes('User.Id')
                                            );
                                        if (route) {
                                            const menu = metaStore.meta
                                                .get('all')
                                                ?.menu?.items?.find((m) => m.path === route.path);

                                            if (menu) {
                                                navigate(
                                                    {
                                                        pathname: route.path.split('?')[0],
                                                        search: ''
                                                    },
                                                    {
                                                        state: {
                                                            pageTitle:
                                                                route.layout.Name?.[i18n.language],
                                                            filterString: route.path.split('?')[1],
                                                            cacheKey: fnv1aHash(
                                                                `${route.id}_${
                                                                    route.layout.Name?.[
                                                                        i18n.language
                                                                    ]
                                                                }`
                                                            )
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                />
                            </Badge>

                            <Changelog />
                            <ButtonWithTooltips
                                icon={<InfoCircleOutlined />}
                                size={isBigMobile ? 'large' : 'middle'}
                                tooltipTitle={t('notes')}
                                type="text"
                                onClick={() => {
                                    navigate(
                                        { pathname: '/notes' },
                                        { state: { pageTitle: t('notes') } }
                                    );
                                }}
                            />
                            {/* <HelpIcon
                                // text={faqText}
                                text={faq}
                                style={{ width: 32 }}
                                // iconStyle={{ color: 'rgba(0, 0, 0, 0.88)' }}
                                trigger="click"
                                // meta={meta}
                                editable={false}
                                disableEditorToolbar
                                isButton
                                buttonProps={{ type: 'text' }}
                            /> */}

                            <ButtonWithTooltips
                                size={isBigMobile ? 'large' : 'middle'}
                                id="logout"
                                tooltipTitle={t('logout')}
                                tooltipPlacement="bottomLeft"
                                className="header__logout"
                                type="text"
                                icon={<LogoutOutlined />}
                                onClick={handleLogout}
                            />
                        </Space.Compact>
                    </Flex>
                </div>
            </Layout.Header>
        );
    }
);
