import { useMemo, useState } from 'react';

import { Card, List, Tooltip, Typography } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import KeepAlive from 'react-activation';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { metaStore } from 'utils/store/MetaStore';

import { useMedia } from 'react-use';
// import { Tooltip } from '@mui/material';
import { useStoreNavigate } from 'utils/store';
import './SmartOverviewPage.scss';

interface IOveriew {
    key: string;
    title: string;
    icon: string;
    path: string;
    filterString: string;
}

export const SmartOverviewPage = observer(() => {
    const location = useLocation();
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const storeNavigate = useStoreNavigate();
    const [currentMenuId, setCurrentMenuId] = useState('');
    const [truncated, setTruncated] = useState({});
    const isTouchScreen = useMedia('(hover: none)');

    const routes = useMemo(() => {
        return [...(toJS(metaStore.meta.get('all')?.routes) || [])];
    }, []);

    const dataSource = useMemo(() => {
        const dataSource: IOveriew[] = [];

        routes.forEach((route) => {
            const menu = [...(toJS(metaStore.meta.get('all')?.menu?.items) || [])].find(
                (m) => !m.is_hidden && m.path === route.path
            );

            const stateFilterStr = location.state?.filterString;

            const pathname = `${location.pathname}${stateFilterStr ? `?${stateFilterStr}` : ''}`;

            if (menu && route.path === pathname) {
                setCurrentMenuId(menu.id);
            }

            if (menu && menu.parent_id === currentMenuId) {
                const key = menu.id;
                const title = menu.name
                    ? menu.name[language]
                    : (t(menu.path.split('/').at(-1) as string) as string);
                const icon = '';
                const [path, filterString] = menu.path.split('?');

                dataSource.push({ key, title, icon, path, filterString });
            }
        });

        return dataSource;
    }, [currentMenuId, language, location.pathname, location.state?.filterString, routes, t]);

    // console.log(dataSource);

    return (
        <KeepAlive>
            <List
                className="smart_overview_page"
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 4,
                    xxl: 5
                }}
                style={{ padding: 10 }}
                dataSource={dataSource}
                renderItem={(item, index) =>
                    item ? (
                        <List.Item
                            onClick={
                                item?.path
                                    ? () =>
                                          storeNavigate(
                                              { pathname: item.path },
                                              {
                                                  state: {
                                                      pageTitle: item.title,
                                                      cacheKey: item.key,
                                                      filterString: item.filterString
                                                  }
                                              }
                                          )
                                    : undefined
                            }
                        >
                            <Tooltip
                                title={
                                    isTouchScreen
                                        ? undefined
                                        : truncated[index]
                                        ? item.title
                                        : undefined
                                }
                            >
                                <Card className="smart_overview_page__card">
                                    <Typography.Paragraph
                                        ellipsis={{
                                            onEllipsis: (isTruncated: boolean) =>
                                                setTruncated((prev) => ({
                                                    ...prev,
                                                    [index]: isTruncated
                                                }))
                                        }}
                                        style={{ marginBottom: 0, fontSize: '1rem' }}
                                    >
                                        {item.title}
                                    </Typography.Paragraph>
                                </Card>
                            </Tooltip>
                        </List.Item>
                    ) : null
                }
            />
        </KeepAlive>
    );
});
