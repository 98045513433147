import { PlainObject } from '@gilbarbara/types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { v4 } from 'uuid';

dayjs.extend(timezone);

function extractTimezone(input: string): string | null {
    // Регулярное выражение для поиска таймзоны в формате +HH:MM или -HH:MM
    const timezoneRegex = /[+-]\d{2}:\d{2}$/;
    const match = input.match(timezoneRegex);

    // Если таймзона найдена, вернуть ее, иначе вернуть null
    return match ? match[0] : null;
}

export const transformSlots = (
    intervals: PlainObject[],
    propertyNames: { StartDateAt: string; EndDateAt: string } = {
        StartDateAt: 'StartDateAt',
        EndDateAt: 'EndDateAt'
    }
) => {
    const slots: PlainObject[] = [];

    // console.log('I', intervals);

    intervals.forEach((interval) => {
        const startDateString =
            (interval.StartAt as string) ?? (interval[propertyNames.StartDateAt] as string);
        const startTZ = extractTimezone(startDateString);

        const endDateString =
            (interval.EndAt as string) ?? (interval[propertyNames.EndDateAt] as string);
        const endTZ = extractTimezone(endDateString);

        const start = dayjs(startDateString).tz(startTZ || undefined);
        const startMinutes = start.get('minutes');
        const startPercent = ((60 - startMinutes) / 60) * 100;

        const end = dayjs(endDateString).tz(endTZ || undefined);
        const endMinutes = end.get('minutes');
        const endPercent = ((endMinutes || 60) / 60) * 100;

        const startHour = start.get('hours');
        const endHour = end.get('hours') || 24;

        // console.log(start, end, startHour, endHour);

        if (startHour === endHour) {
            const StartDateAt = start
                .set('hours', startHour)
                .set('minutes', startMinutes)
                .format('YYYY-MM-DDTHH:mm:ssZ');

            const EndDateAt = end
                .set('hours', endHour)
                .set('minutes', endMinutes)
                .format('YYYY-MM-DDTHH:mm:ssZ');

            slots.push({
                ...interval,
                Id: v4(),
                SlotIndex: startHour,
                SlotDate: interval.SlotDate ?? interval.Date,
                StartDateAt,
                EndDateAt,
                SlotPercent: endPercent,
                IsSlotStart: false,
                IsSlotEnd: true
            });
        } else {
            for (let hour = startHour; hour <= endHour; hour++) {
                const isStartHour = hour === startHour;
                const isEndHour = hour + 1 === endHour;

                const StartDateAt = start
                    .set('hours', hour)
                    .set('minutes', isStartHour ? startMinutes : 0)
                    .format('YYYY-MM-DDTHH:mm:ssZ');
                const EndDateAt = end
                    .set('hours', hour + 1)
                    .set('minutes', isEndHour ? endMinutes : 0)
                    .format('YYYY-MM-DDTHH:mm:ssZ');

                if (endMinutes && isEndHour) {
                    slots.push({
                        ...interval,
                        Id: v4(),
                        SlotIndex: hour,
                        SlotDate: interval.SlotDate ?? interval.Date,
                        StartDateAt,
                        EndDateAt: end
                            .set('hours', hour + 1)
                            .set('minutes', 0)
                            .format('YYYY-MM-DDTHH:mm:ssZ'),
                        SlotPercent: isStartHour ? startPercent : 100,
                        IsSlotStart: isStartHour,
                        IsSlotEnd: false
                    });
                    slots.push({
                        ...interval,
                        Id: v4(),
                        SlotIndex: hour + 1,
                        SlotDate: interval.SlotDate ?? interval.Date,
                        StartDateAt: end
                            .set('hours', hour + 1)
                            .set('minutes', 0)
                            .format('YYYY-MM-DDTHH:mm:ssZ'),
                        EndDateAt,
                        SlotPercent: isEndHour ? endPercent : 100,
                        IsSlotStart: false,
                        IsSlotEnd: isEndHour
                    });
                } else {
                    slots.push({
                        ...interval,
                        Id: v4(),
                        SlotIndex: hour,
                        SlotDate: interval.SlotDate ?? interval.Date,
                        StartDateAt,
                        EndDateAt,
                        SlotPercent: isStartHour ? startPercent : isEndHour ? endPercent : 100,
                        IsSlotStart: isStartHour,
                        IsSlotEnd: isEndHour
                    });
                }
            }
        }
    });

    return slots;
};
