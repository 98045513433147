import { toJS } from 'mobx';
import { useCallback, useState } from 'react';
import { metaStore } from 'utils/store/MetaStore';

interface UseQueriesProps {
    meta: string;
    id: string;
    currentPathForGetMetaInfo?: string;
}

export const useQueries = ({ meta, id, currentPathForGetMetaInfo }: UseQueriesProps) => {
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(
        async (fetchInfo = true, fetchGet = true) => {
            setLoading(true);

            const result = {
                info: toJS(metaStore.meta.get(meta)?.info),
                get: toJS(metaStore.meta.get(meta)?.get?.[id])
            };

            try {
                if (fetchInfo) {
                    result.info = await metaStore.getInfo(meta, currentPathForGetMetaInfo);
                }
                if (fetchGet) {
                    result.get = await metaStore.makeGet({
                        meta,
                        id: id || undefined
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }

            return result;
        },
        [currentPathForGetMetaInfo, id, meta]
    );

    const refresh = useCallback(async () => {
        const res = await fetch(false, true);

        return res.get?.object || {};
    }, [fetch]);

    const releaseLock = useCallback(async (lockId: string) => {
        const release = await metaStore.releaseLock({ lock_id: lockId });

        return !!release;
    }, []);

    const acquireLock = useCallback(async () => {


        try {
            const { objects } = await metaStore.makeSelect({
                meta: 'InfoObjectLocks',
                fields: [],
                filters: `Object_Id=eq.${id}`,
                page: 1,
                page_size: 50000,
                sort: ''
            });

            if (objects.length > 0) {
                await metaStore.releaseLock({ lock_id: objects[0].Id });
            }

            const acquire = await metaStore.acquireLock({ meta, id });

            return acquire?.Id || null;
        } catch (error) {
            console.error(error);

            return null;
        }
    }, [id, meta]);

    return { loading, fetch, refresh, acquireLock, releaseLock };
};
