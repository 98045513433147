import { LabelValue, PlainObject } from '@gilbarbara/types';
import { Button, Tag, Tooltip } from 'antd';
import { SelectProps } from 'antd/es/select';
import { toJS } from 'mobx';
import React, { memo, Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useEffectOnce, useMedia } from 'react-use';

import { Route } from 'modules/services/backend-api/generated_api';
import { BaseTableRow, SupabaseViewRow, ViewName } from 'modules/supabase/types/Dataset';
import { checkAndMakeTreeData, buildTreeWithGrouping, fnv1aHash } from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';
import { useStoreNavigate } from 'utils/store';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';

import { SmartSearchList } from '../SmartSelectField/components';
import {
    renderOptionLabel,
    transformDataToOptions,
    useSelectData
} from '../SmartSelectField/utils';
import { SmartSelectInner } from '../SmartSelectField/ui';

import './SmartMultiSelectField.scss';
import { transformFilterString } from '../../utils/transformFilterString';

type SupabaseViewType = SupabaseViewRow<ViewName> & BaseTableRow;

// TODO: тип прописать
type TValue = any[];

interface SelectFieldProps {
    popoverContainerHtmlId?: string; // id
    filters?: string;
    onChange?: (newValue: SupabaseViewType[] | null) => void; // handler
    maxCount?: number; // ограничение на максимальное кол-во выбранных опций
    loading?: boolean;
    metaName: string;
    value?: TValue;
    isDisabled?: boolean;
    style?: React.CSSProperties;
    size?: 'small' | 'middle' | 'large';
    placeholder?: string;
    treeOptions?: {
        forceDisable?: boolean;
        groupKeys?: string[];
        parentField?: string;
        checkStrictly?: boolean;
    };
}

type TagRender = SelectProps['tagRender'];

const getCuttedData = (array: IObjectWithId[]) => {
    return array.map((data) => ({
        Id: data.Id,
        Code: data.Code,
        Key: data.Key,
        Name: data.Name,
        PluralName: data.PluralName
    }));
};

const defaultValue: TValue = [];

export const SmartMultiSelectField = memo<SelectFieldProps>(
    ({
        popoverContainerHtmlId,
        filters = '',
        metaName,
        onChange = () => {},
        value: propsValue = defaultValue,
        placeholder,
        maxCount,
        loading,
        size,
        style,
        isDisabled = false,
        treeOptions
    }) => {
        const value = propsValue || defaultValue;
        const checkStrictly = treeOptions?.checkStrictly ?? true;

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const location = useLocation();
        const navigate = useStoreNavigate();
        const isTouchScreen = useMedia('(hover: none)');

        const [isOpen, setOpen] = useState(false);

        const { fetchData, dataSource, metaSource, modalFields, modalTitle, isLoading } =
            useSelectData({
                meta: metaName,
                filters
            });

        const isMetaReadOnly = metaSource?.IsReadOnly || false;
        const viewFieldName = metaSource?.ViewFieldName || 'Name';
        const refViewTemplate = metaSource?.RefViewTemplate;

        useEffectOnce(() => {
            fetchData();
        });

        const handleOpen = useCallback(() => {
            setOpen(true);
        }, []);
        const handleClose = useCallback(() => {
            setOpen(false);
        }, []);

        const tagRender = useCallback<NonNullable<TagRender>>(
            (props) => {
                const { onClose, isMaxTag, label } = props;
                const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                };

                const needle = value.find((data) => data.Id === label);

                return isMaxTag ? (
                    <Tag onMouseDown={onPreventMouseDown} className="multi_select_field__tag">
                        <span>{label}</span>
                    </Tag>
                ) : (
                    <Tag
                        onMouseDown={onPreventMouseDown}
                        closable={!isDisabled}
                        onClose={onClose}
                        className="multi_select_field__tag"
                    >
                        {/* <span>{label}</span> */}
                        <span>{needle ? renderOptionLabel(needle, viewFieldName) : label}</span>
                    </Tag>
                );
            },
            [isDisabled, value, viewFieldName]
        );

        const outputOptions = useMemo<LabelValue[]>(() => {
            // const treeData = checkAndMakeTreeData({
            //     data: dataSource,
            //     groupKeys: treeOptions?.groupKeys ?? [],
            //     parentField: treeOptions?.parentField ?? 'Parent',
            //     viewFieldName
            // });

            const treeData = buildTreeWithGrouping(
                dataSource,
                treeOptions?.parentField ?? 'Parent',
                treeOptions?.groupKeys ?? [],
                viewFieldName
            );

            const options = transformDataToOptions(
                treeData,
                viewFieldName,
                language,
                refViewTemplate
            );
            // return dataSource.map((item) => ({
            //     value: item.Id,
            //     label: renderOptionLabel(item, viewFieldName)
            // }));
            // console.log('[SmartSelectField] options:', options);

            return options;
        }, [
            dataSource,
            language,
            refViewTemplate,
            treeOptions?.groupKeys,
            treeOptions?.parentField,
            viewFieldName
        ]);

        const selectValue = useMemo(() => {
            const selectValue: string[] = [];

            value.forEach((v) => {
                const needle = dataSource.find((data) => data.Id === v.Id);
                if (needle) {
                    selectValue.push(needle?.Id);
                } else {
                    selectValue.push(v.Id);
                }
            });

            return selectValue;
        }, [dataSource, value]);

        const handleChangeInline = useCallback(
            (newValues: (string | LabelValue)[]) => {
                let ids = newValues;
                if (checkStrictly) {
                    ids = (newValues as LabelValue[]).map((newValue) => newValue.value);
                }

                onChange(getCuttedData(dataSource.filter((data) => ids.includes(data.Id))));
            },
            [checkStrictly, dataSource, onChange]
        );

        const handleChangeModal = useCallback(
            (selectedRows: IObjectWithId[]) => onChange(getCuttedData(selectedRows)),
            [onChange]
        );

        const handleCreate = useCallback(async () => {
            const metaRoutes = toJS(metaStore.meta.get('all')?.routes);

            let needle = metaRoutes?.find((route) => route.meta === metaName);

            if (!needle) needle = { path: `/other/${metaName}` } as Route;

            const path = needle?.path.split('?')[0];
            const filterString = needle?.path.split('?')[1];

            let pathname = `${path}/new`;
            if (location.pathname.endsWith('/')) {
                pathname = `${path}new`;
            }

            const singularName =
                metaSource?.SingularName?.[language] || t(pathname.split('/').at(-2) || ''); // get pre last pathname element for correctly displaying object context

            let preDataFromParams: PlainObject<any> = {};

            if (filterString) {
                const fields = metaSource?.Fields ?? [];
                preDataFromParams = await transformFilterString(filterString, fields, language);
            }

            const state: PlainObject<any> = {
                // ...location.state,
                cacheKey: fnv1aHash(`${metaName}_new_edit`),
                pageTitle: `${t('new')} (${singularName})`,
                data: preDataFromParams
            };

            if (metaSource?.Fields.find((f) => f.FieldName === 'IsActive')) {
                state.data.IsActive = true;
            }

            navigate({ pathname }, { state });
        }, [
            language,
            location.pathname,
            location.state,
            metaName,
            metaSource?.SingularName,
            navigate,
            t
        ]);

        return (
            <>
                <Suspense fallback={<></>}>
                    <SmartSearchList
                        fields={modalFields}
                        title={modalTitle}
                        meta={metaName}
                        open={isOpen}
                        selectionType="checkbox"
                        onCancel={handleClose}
                        data={dataSource}
                        initialSelectedRows={value || []}
                        onOk={handleChangeModal}
                    />
                </Suspense>
                <SmartSelectInner
                    size={size}
                    disableTree={treeOptions?.forceDisable}
                    loading={loading}
                    allowClear
                    getPopupContainer={
                        popoverContainerHtmlId
                            ? () => document.getElementById(popoverContainerHtmlId) as HTMLElement
                            : undefined
                    }
                    multiple
                    maxTagCount="responsive"
                    popupClassName="multi_select_field__popup"
                    placeholder={placeholder ?? t('no_value')}
                    style={{
                        width: '100%',
                        ...style
                    }}
                    maxCount={maxCount}
                    value={selectValue}
                    options={outputOptions}
                    tagRender={tagRender}
                    treeCheckable
                    treeCheckStrictly={checkStrictly}
                    onFocus={fetchData}
                    notFoundContent={isLoading ? <Loader size="small" /> : undefined}
                    dropdownRender={
                        !isLoading
                            ? (menu) => (
                                  <>
                                      {menu}
                                      {outputOptions.length > 0 && (
                                          <Button size={size} type="link" onClick={handleOpen}>
                                              {t('show_more')}
                                          </Button>
                                      )}
                                      <br />
                                      <Button
                                          size={size}
                                          disabled={isMetaReadOnly}
                                          type="link"
                                          onClick={handleCreate}
                                      >
                                          {t('create')}
                                      </Button>
                                  </>
                              )
                            : undefined
                    }
                    maxTagPlaceholder={(omittedValues) => {
                        return (
                            <Tooltip
                                title={
                                    isTouchScreen
                                        ? undefined
                                        : omittedValues.map(({ label }) => label).join(', ')
                                }
                            >
                                <span>+{omittedValues.length} ...</span>
                            </Tooltip>
                        );
                    }}
                    onChange={handleChangeInline}
                    disabled={isDisabled}
                />
            </>
        );
    }
);
