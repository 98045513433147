import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    MDXEditor,
    MDXEditorMethods,
    // ** Plugins **
    headingsPlugin,
    imagePlugin,
    quotePlugin,
    listsPlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    linkDialogPlugin,
    linkPlugin,
    markdownShortcutPlugin,
    tablePlugin,
    diffSourcePlugin,
    // ** Tollbar Components **
    UndoRedo,
    BoldItalicUnderlineToggles,
    // BlockTypeSelect,
    CodeToggle,
    CreateLink,
    InsertImage,
    InsertTable,
    DiffSourceToggleWrapper,
    InsertFrontmatter,
    frontmatterPlugin,
    InsertThematicBreak,
    ListsToggle,
    Separator
} from '@mdxeditor/editor';
import { v4 } from 'uuid';
import { storageApiUrl } from 'modules/client/itvecturaClient';
import { metaStore } from 'utils/store/MetaStore';
import { useNotifications } from 'utils/hooks';
import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
// import { getFileUrlForDownload } from 'smart/utils';

import '@mdxeditor/editor/style.css';
import './MarkdownEditor.scss';
import { useStoreNavigate } from 'utils/store';

interface MarkdownEditorProps {
    readOnly?: boolean;
    disableToolbar?: boolean;
    meta?: string;
    mode?: string;
    // readOnlyDiff?: boolean;
    value: string | undefined;
    fileUploadPath?: string;
    fileUploadBucket?: string;
    onChange?: (newValue: string | undefined) => void;
}

function getFileExtension(filename: string): string {
    const parts = filename.split('.');

    return parts.length > 1 ? parts.pop() : '';
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
    readOnly,
    disableToolbar,
    // readOnlyDiff,
    fileUploadBucket = 'public',
    fileUploadPath,
    value = '',
    meta,
    mode,
    onChange
}) => {
    const editor = useRef<MDXEditorMethods>(null);
    const initialValue = useRef(value);

    const { t } = useTranslation();
    const { notification } = useNotifications();
    const navigate = useStoreNavigate();

    // console.log(value, editor.current, readOnly, disableToolbar);
    // console.log(meta, value);

    useEffect(() => {
        if (meta) editor.current?.setMarkdown?.(value);
    }, [meta, mode]);

    // const pluginsList = useMemo(() => {
    //     const res = [
    //         headingsPlugin(),
    //         quotePlugin(),
    //         listsPlugin(),
    //         thematicBreakPlugin(),
    //         linkPlugin(),
    //         markdownShortcutPlugin(),
    //         tablePlugin(),
    //         linkDialogPlugin({
    //             linkAutocompleteSuggestions: ['https://itvectura.com', 'https://itvectura.ru']
    //         }),
    //         // codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
    //         // codeMirrorPlugin({
    //         //     codeBlockLanguages: {
    //         //         js: 'JavaScript',
    //         //         css: 'CSS',
    //         //         html: 'HTML',
    //         //         ts: 'TypeScript',
    //         //         sql: 'SQL'
    //         //     }
    //         // }),
    //         // diffSourcePlugin({
    //         //     diffMarkdown: 'An older version',
    //         //     viewMode: 'diff',

    //         //     readOnlyDiff
    //         // }),
    //         diffSourcePlugin({
    //             diffMarkdown: initialValue?.current
    //         }),
    //         imagePlugin({
    //             imageUploadHandler: async (file) => {
    //                 try {
    //                     if (typeof file === 'string')
    //                         throw new Error(t('file_uncorrect_type') as string);

    //                     // Проверяем, что файл выбран
    //                     if (!file) {
    //                         throw new Error(t('file_not_choosen') as string);
    //                     }

    //                     const initialName = `${file.name}`;
    //                     let techName = v4();

    //                     const ext = getFileExtension(initialName);
    //                     if (ext) {
    //                         techName += `.${ext}`;
    //                     }

    //                     Object.defineProperty(file, 'name', {
    //                         // value: translitRu(file.name)
    //                         value: techName
    //                     });

    //                     file.initial_name = initialName;

    //                     let filePath = '';
    //                     if (fileUploadPath) {
    //                         filePath = `${fileUploadPath}/`;
    //                     }
    //                     if (file.name) {
    //                         filePath += file.name;
    //                     }

    //                     const uploadFN = metaStore?.api?.client?.storage?.upload;

    //                     if (uploadFN) {
    //                         const { data, error } = await uploadFN(
    //                             fileUploadBucket,
    //                             filePath,
    //                             file
    //                         );

    //                         // Если ошибка, то бросаем исключение
    //                         if (error && !data?.path) throw error;
    //                         if (!data?.path) throw new Error(t('file_upload_error') as string);

    //                         // return getFileUrlForDownload(fileUploadBucket, data.path as string);
    //                         // return { storageApiUrl, bucket: fileUploadBucket, path: data?.path };
    //                         return `${storageApiUrl}/download/${fileUploadBucket}/${data.path}`;
    //                     }

    //                     // else
    //                     throw new Error(t('storage_is_undefiend') as string);
    //                 } catch (error) {
    //                     notification.error({
    //                         message: t('file_upload_error') as string,
    //                         description: makeErrorReadable((error as Error).message),
    //                         duration: 0
    //                     });

    //                     // eslint-disable-next-line no-console
    //                     console.error((error as Error).message); // точно будет message, так как явно его передаем выше
    //                     throw error;
    //                 }
    //                 // return Promise.resolve('https://picsum.photos/200/300');
    //             }
    //         }),
    //         toolbarPlugin({
    //             toolbarClassName: 'my-classname',
    //             toolbarContents: () => (
    //                 <>
    //                     <UndoRedo />
    //                     <BoldItalicUnderlineToggles />
    //                     {/* <BlockTypeSelect /> */}
    //                     <CodeToggle />
    //                     {/* <InsertCodeBlock /> */}
    //                     {/* <ConditionalContents
    //                     options={[
    //                         {
    //                             when: (editor) => editor?.editorType === 'codeblock',
    //                             contents: () => <ChangeCodeMirrorLanguage />
    //                         },
    //                         {
    //                             fallback: () => <InsertCodeBlock />
    //                         }
    //                     ]}
    //                 /> */}
    //                     <CreateLink />
    //                     <InsertImage />
    //                     <InsertTable />
    //                     <DiffSourceToggleWrapper
    //                         options={readOnly ? ['rich-text'] : ['rich-text', 'diff', 'source']}
    //                         children={null}
    //                     />
    //                     {/* <DiffSourceToggleWrapper /> */}
    //                 </>
    //             )
    //         })

    //         // linkPlugin(),
    //         // linkDialogPlugin({
    //         //     linkAutocompleteSuggestions: ['https://itvectura.com', 'https://google.com']
    //         // }),
    //         // // toolbarPlugin(), // TODO: toolbar
    //         // tablePlugin(),
    //         // codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
    //         // // sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
    //         // codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } })
    //     ];

    //     // if (!readOnly) {
    //     //     res.push();
    //     // }

    //     return res;
    // }, [fileUploadBucket, fileUploadPath, notification, t, readOnly]);

    return (
        <div>
            <MDXEditor
                ref={editor}
                markdown={value}
                onChange={onChange}
                readOnly={readOnly}
                translation={t}
                contentEditableClassName="markdown_editor__content"
                plugins={[
                    headingsPlugin(),
                    quotePlugin(),
                    listsPlugin(),
                    thematicBreakPlugin(),
                    linkPlugin(),
                    markdownShortcutPlugin(),
                    tablePlugin(),
                    frontmatterPlugin(),
                    linkDialogPlugin({
                        linkAutocompleteSuggestions: [
                            'https://itvectura.com',
                            'https://itvectura.ru'
                        ]
                    }),
                    // codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
                    // codeMirrorPlugin({
                    //     codeBlockLanguages: {
                    //         js: 'JavaScript',
                    //         css: 'CSS',
                    //         html: 'HTML',
                    //         ts: 'TypeScript',
                    //         sql: 'SQL'
                    //     }
                    // }),
                    // diffSourcePlugin({
                    //     diffMarkdown: 'An older version',
                    //     viewMode: 'diff',

                    //     readOnlyDiff
                    // }),
                    diffSourcePlugin({
                        diffMarkdown: initialValue?.current
                    }),
                    imagePlugin({
                        imageUploadHandler: async (file) => {
                            try {
                                if (typeof file === 'string')
                                    throw new Error(t('file_uncorrect_type') as string);

                                // Проверяем, что файл выбран
                                if (!file) {
                                    throw new Error(t('file_not_choosen') as string);
                                }

                                const initialName = `${file.name}`;
                                let techName = v4();

                                const ext = getFileExtension(initialName);
                                if (ext) {
                                    techName += `.${ext}`;
                                }

                                Object.defineProperty(file, 'name', {
                                    // value: translitRu(file.name)
                                    value: techName
                                });

                                file.initial_name = initialName;

                                let filePath = '';
                                if (fileUploadPath) {
                                    filePath = `${fileUploadPath}/`;
                                }
                                if (file.name) {
                                    filePath += file.name;
                                }

                                const uploadFN = metaStore?.api?.client?.storage?.upload;

                                if (uploadFN) {
                                    const { data, error } = await uploadFN(
                                        fileUploadBucket,
                                        filePath,
                                        file
                                    );

                                    // Если ошибка, то бросаем исключение
                                    if (error && !data?.path) throw error;
                                    if (!data?.path)
                                        throw new Error(t('file_upload_error') as string);

                                    // return getFileUrlForDownload(fileUploadBucket, data.path as string);
                                    // return { storageApiUrl, bucket: fileUploadBucket, path: data?.path };
                                    return `${storageApiUrl}/download/${fileUploadBucket}/${data.path}`;
                                }

                                // else
                                throw new Error(t('storage_is_undefiend') as string);
                            } catch (error) {
                                notification.error({
                                    message: t('file_upload_error') as string,
                                    description: makeErrorReadable((error as Error).message),
                                    duration: 0
                                });

                                // eslint-disable-next-line no-console
                                console.error((error as Error).message); // точно будет message, так как явно его передаем выше
                                throw error;
                            }
                            // return Promise.resolve('https://picsum.photos/200/300');
                        }
                    }),
                    toolbarPlugin({
                        toolbarClassName: 'markdown_editor__toolbar',
                        toolbarContents: () => (
                            <>
                                <UndoRedo />

                                <Separator />

                                <BoldItalicUnderlineToggles />
                                <CodeToggle />

                                <Separator />

                                {/* <BlockTypeSelect /> */}
                                {/* <InsertCodeBlock /> */}
                                {/* <ConditionalContents
                                options={[
                                    {
                                        when: (editor) => editor?.editorType === 'codeblock',
                                        contents: () => <ChangeCodeMirrorLanguage />
                                    },
                                    {
                                        fallback: () => <InsertCodeBlock />
                                    }
                                ]}
                            /> */}
                                <ListsToggle />

                                <Separator />

                                <CreateLink />
                                <InsertImage />

                                <Separator />

                                <InsertTable />
                                <InsertThematicBreak />

                                <Separator />

                                <InsertFrontmatter />

                                <DiffSourceToggleWrapper
                                    options={
                                        readOnly ? ['rich-text'] : ['rich-text', 'diff', 'source']
                                    }
                                    children={null}
                                />
                            </>
                        )
                    })

                    // linkPlugin(),
                    // linkDialogPlugin({
                    //     linkAutocompleteSuggestions: ['https://itvectura.com', 'https://google.com']
                    // }),
                    // // toolbarPlugin(), // TODO: toolbar
                    // tablePlugin(),
                    // codeBlockPlugin({ defaultCodeBlockLanguage: 'js' }),
                    // // sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
                    // codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', css: 'CSS' } })
                ]}
            />
        </div>
    );
};
