import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Flex, Modal, Popover } from 'antd';
import { isDefined } from 'is-lite/exports';
import { useUserData } from 'modules/client/useAuthUser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync, useUpdateEffect } from 'react-use';
import { MarkdownEditor } from 'smart/components';
import { useNotifications } from 'utils/hooks';
// import { makeErrorReadable } from 'utils/helpers/makeErrorReadable';
import { metaStore } from 'utils/store/MetaStore';

interface HelpIconProps extends React.PropsWithChildren {
    text?: string;
    onSave?: (value: string | undefined) => void;
    meta?: string;
    trigger?: 'click' | 'hover';
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    placement?: 'start' | 'end';
    viewMode?: 'inline' | 'modal';
    isButton?: boolean;
    disableEditorToolbar?: boolean;
    buttonProps?: ButtonProps;
    editable?: boolean;
}

export const HelpIcon: React.FC<HelpIconProps> = ({
    text,
    meta,
    children,
    style,
    iconStyle,
    isButton,
    buttonProps,
    editable,
    disableEditorToolbar,
    onSave,
    viewMode = 'modal',
    trigger = 'click',
    placement = 'end'
}) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { isPowerUser } = useUserData();
    const { notification } = useNotifications();

    const readOnly = isDefined(editable) ? !editable || !isPowerUser : !isPowerUser;

    const [helpOpen, setHelpOpen] = useState(false);
    const [description, setDescription] = useState<string | undefined>(text);
    useUpdateEffect(() => {
        setDescription(text);
    }, [text]);

    const [saveLoading, setSaveLoading] = useState(false);

    const metaID = useAsync(async () => {
        if (meta) {
            return (
                await metaStore.makeSelect({
                    meta: 'InfoMeta',
                    filters: `Code=eq.${meta}`,
                    page: 1,
                    page_size: 1
                })
            )?.objects?.[0]?.Id;
        }

        return null;
    }, [meta]);

    return (
        <Flex
            style={{
                width: '100%',
                alignItems: !children ? 'center' : undefined,
                justifyContent: !children ? 'center' : undefined,
                ...style
            }}
            gap={3}
        >
            {placement === 'end' ? children : null}

            {isButton ? (
                <>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setHelpOpen((prev) => !prev);
                        }}
                        icon={
                            <InfoCircleOutlined
                                style={{
                                    // justifySelf: 'flex-end',
                                    // color: 'rgba(0, 0, 0, 0.2)',
                                    // transition: 'all 0.3s ease',
                                    // marginLeft: placement === 'end' ? 5 : undefined,
                                    // marginRight: placement === 'start' ? 5 : undefined,
                                    // marginTop: 'auto',
                                    // marginBottom: 'auto',
                                    ...iconStyle
                                }}
                            />
                        }
                        {...buttonProps}
                    />

                    {viewMode === 'inline' ? (
                        <MarkdownEditor
                            readOnly={readOnly}
                            value={description}
                            onChange={setDescription}
                        />
                    ) : (
                        <Modal
                            title={t('description')}
                            open={helpOpen}
                            onOk={async () => {
                                if (isButton && meta && metaID.value) {
                                    setSaveLoading(true);
                                    if (onSave) {
                                        onSave(description);
                                    } else {
                                        try {
                                            await metaStore.makeSave({
                                                meta: 'InfoMeta',
                                                objects: [
                                                    {
                                                        Id: metaID.value,
                                                        Code: meta,
                                                        Description: { [language]: description }
                                                    }
                                                ]
                                            });
                                        } catch (error) {
                                            console.error(error);
                                            notification.error({
                                                // key,
                                                message: t('error'),
                                                description: (error as Error).message
                                            });
                                        }
                                    }

                                    setSaveLoading(false);
                                    setHelpOpen(false);
                                }
                            }}
                            onCancel={() => setHelpOpen(false)}
                            okText={!readOnly ? t('save') : t('ok')}
                            cancelText={!readOnly ? t('cancel') : t('close')}
                            okButtonProps={{
                                disabled: readOnly,
                                loading: saveLoading,
                                style: readOnly ? { display: 'none' } : undefined
                            }}
                            width="80%"
                            centered
                            destroyOnClose
                            styles={{
                                body: { maxHeight: '50vh', overflow: 'auto' }
                            }}
                        >
                            <MarkdownEditor
                                disableToolbar={disableEditorToolbar}
                                readOnly={readOnly}
                                value={description}
                                onChange={setDescription}
                            />
                        </Modal>
                    )}
                </>
            ) : text ? (
                <>
                    <Popover
                        placement="bottomLeft"
                        arrow={false}
                        content={text}
                        destroyTooltipOnHide
                        mouseEnterDelay={0.4}
                        trigger={[trigger]}
                    >
                        <InfoCircleOutlined
                            style={{
                                justifySelf: 'flex-end',
                                color: 'rgba(0, 0, 0, 0.2)',
                                transition: 'all 0.3s ease',
                                marginLeft: placement === 'end' ? 5 : undefined,
                                marginRight: placement === 'start' ? 5 : undefined,
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                ...iconStyle
                            }}
                        />
                    </Popover>
                </>
            ) : placement === 'start' ? (
                <div
                    style={{
                        width: 14,
                        height: 14,
                        // marginLeft: placement === 'end' ? 5 : undefined,
                        marginRight: placement === 'start' ? 5 : undefined
                    }}
                />
            ) : null}
            {placement === 'start' ? (
                <div style={{ width: 'calc(100% - 22px)' }}>{children}</div>
            ) : null}
        </Flex>
    );
};
