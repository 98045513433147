import { AVAILABLE_FILTER_OPERATORS, FilterField, FilterOption } from '../types';
import { isArrayType } from './isArrayType';

export const getAvailableFilterOperators = (filterField: FilterField): FilterOption[] => {
    if (
        filterField.field.ValueType?.includes('text') ||
        filterField.field.ValueType?.includes('multilang_text') ||
        filterField.field.ColumnName === 'key'
    ) {
        return [
            // 'fuzzy', // NOTE: ?нужны ли они вообще если есть contains
            'equals',
            'notEquals',
            'contains',
            // 'startsWith', // NOTE: ?нужны ли они вообще если есть contains
            // 'endsWith', // NOTE: ?нужны ли они вообще если есть contains
            'empty',
            'notEmpty'
        ];
    }

    if (
        filterField.field.ValueType?.includes('code') ||
        filterField.field.ValueType?.includes('key') ||
        filterField.field.ValueType?.includes('id') ||
        filterField.field.ValueType?.includes('type') ||
        filterField.field.ValueType?.includes('status') ||
        filterField.field.ValueType?.includes('provision_indicator') ||
        filterField.field.ValueType?.includes('flow')
    ) {
        return ['equals', 'notEquals', 'empty', 'notEmpty', 'in_list', 'not_in_list'];
    }

    if (
        filterField.field.ValueType?.includes('daterange') ||
        filterField.field.ValueType?.includes('daterange_with_datetime_return') ||
        filterField.field.ValueType?.includes('datetimerange') ||
        filterField.field.ValueType?.includes('datetime_range') ||
        filterField.field.ValueType?.includes('datetimelocalrange') ||
        filterField.field.ValueType?.includes('timerange')
    ) {
        return [
            // 'today',
            // 'tomorrow',
            // 'currentWeek',
            // 'currentMonth',
            'contains',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty'
        ];
    }

    if (
        filterField.field.ValueType?.includes('datetime') ||
        filterField.field.ValueType?.includes('datetimelocal')
    ) {
        return [
            'equals',
            'notEquals',
            'today',
            'tomorrow',
            'lastHour',
            'lastWeek',
            'lastMonth',
            'currentHour',
            'currentWeek',
            'currentMonth',
            'between',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty'
        ];
    }

    if (filterField.field.ValueType?.includes('date')) {
        return [
            'equals',
            'notEquals',
            'today',
            'tomorrow',
            'lastHour',
            'lastWeek',
            'lastMonth',
            'currentWeek',
            'currentMonth',
            'equals',
            'notEquals',
            'between',
            'lessThanOrEqualTo',
            'lessThan',
            'greaterThan',
            'greaterThanOrEqualTo',
            'empty',
            'notEmpty'
        ];
    }

    if (isArrayType(filterField.field.ValueType)) {
        return ['contains', 'empty', 'notEmpty'];
    }

    return AVAILABLE_FILTER_OPERATORS;
};
