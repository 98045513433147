import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Menu, Typography, Card, Button, Space, Flex } from 'antd';
import {
    InfoCircleOutlined,
    VideoCameraOutlined,
    FileTextOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import { useAsync, useAsyncRetry } from 'react-use';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { buildTreeWithGrouping } from 'smart/utils';
import { MenuItemType } from 'antd/es/menu/interface';
import { LazyIcon } from 'smart/ui';
import { useTranslation } from 'react-i18next';
import { MarkdownEditor } from 'smart/components';
import { useUserData } from 'modules/client/useAuthUser';
import { Loader } from 'ui/Loader/Loader';

import './NotesPage.scss';
import { isDefined } from 'is-lite/exports';
import { useNotifications } from 'utils/hooks';

const { Sider, Content } = Layout;
const { Title, Paragraph } = Typography;

const removeEmptyFolders = (tree: any[]) => {
    return tree
        .map((node) => ({
            ...node,
            children: node.children?.length ? removeEmptyFolders(node.children) : undefined
        }))
        .filter((node) => !node.data.IsFolder || (node.data.IsFolder && node.children?.length > 0));
};

function findParentCodes(tree: TreeNode[], targetCode: string, parents: string[] = []): string[] {
    for (const node of tree) {
        // Если текущий узел содержит искомый `Code`, возвращаем массив родителей
        if (node.key === targetCode) {
            return parents;
        }

        // Если есть дочерние узлы, спускаемся вглубь дерева
        if (node.children && node.children.length > 0) {
            const result = findParentCodes(node.children, targetCode, [...parents, node.key]);
            if (result.length > 0) {
                return result;
            }
        }
    }

    // Если элемент не найден в данном поддереве, возвращаем пустой массив
    return [];
}

export const NotesPage = memo<{ meta?: string; rootMeta: string }>(({ rootMeta, meta }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const { isPowerUser } = useUserData();
    const { notification } = useNotifications();

    const selectedNoteStartState = useRef<IObjectWithId | null>(null);
    const [selectedNote, setSelectedNote] = useState<IObjectWithId | null>(null);
    const [loading, setLoading] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [activeKey, setActiveKey] = useState<string | undefined>(meta);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const [mode, setMode] = useState<'view' | 'edit'>('view');

    const notes = useAsyncRetry(async () => {
        const notes = await metaStore.makeSelect({
            meta: rootMeta,
            filters: 'or(IsContentExists=eq.true,IsFolder=eq.true)'
        });
        // console.log(notes);

        return notes?.objects;
    }, [rootMeta]);

    const metaID = useAsync(async () => {
        if (rootMeta === 'InfoMetaNotes' && selectedNote?.Code && selectedNote?.IsContentExists) {
            return (
                await metaStore.makeSelect({
                    meta: 'InfoMeta',
                    filters: `Code=eq.${selectedNote.Code}`,
                    page: 1,
                    page_size: 1
                })
            )?.objects?.[0]?.Id;
        }

        return null;
    }, [selectedNote?.Code]);

    const handleClickMenu = useCallback(
        async (note: IObjectWithId) => {
            if (note.IsContentExists) {
                setLoading(true);
                const res = await metaStore.makeGet({ meta: rootMeta, id: note.Id });
                const notesDetail = res.object;

                // console.log(notesDetail);

                setSelectedNote(notesDetail);
                selectedNoteStartState.current = notesDetail;
                setActiveKey(note.Code);
                setLoading(false);
            } else {
                selectedNoteStartState.current = null;
                setSelectedNote(null);
            }
        },
        [rootMeta]
    );

    useEffect(() => {
        if (meta && !notes.loading && notes.value) {
            const note = notes.value.find((note) => note.Code === meta);
            if (note) {
                handleClickMenu(note);
            }
        }
    }, [handleClickMenu, meta, notes.loading, notes.value]);

    // console.log(selectedNote, selectedNote?.Content?.[language]);

    const notesMenuItems = useMemo<MenuItemType[]>(() => {
        const result: MenuItemType[] = (notes.value ?? []).map((note) => ({
            key: note.Code,
            icon: note.Icon ? <LazyIcon icon={note.Icon} /> : null,
            theme: 'light',
            label: note.Name?.[language] ?? note.Code,
            Id: note.Id,
            parent: note.Parent,
            data: note,
            onClick: () => handleClickMenu(note)
        }));

        return removeEmptyFolders(buildTreeWithGrouping(result, 'parent', [], 'label'));
    }, [handleClickMenu, language, notes.value]);

    useEffect(() => {
        if (meta) {
            setOpenKeys(findParentCodes(notesMenuItems, meta) ?? []);
        }
    }, [meta, notesMenuItems]);

    // console.log(notesMenuItems);

    // console.log(isPowerUser);

    const isShowToolbar = isPowerUser && selectedNote?.IsContentExists;

    return (
        <Loader status={notes.loading}>
            <Layout style={{ height: '83vh' }}>
                <Sider width={300} style={{ background: '#fff', borderRight: '1px solid #f0f0f0' }}>
                    <Menu
                        items={notesMenuItems}
                        mode="inline"
                        style={{
                            height: '100%',
                            borderRight: 0,
                            overflowY: 'auto',
                            overflowX: 'hidden'
                        }}
                        openKeys={openKeys}
                        onOpenChange={setOpenKeys}
                        defaultOpenKeys={meta ? findParentCodes(notesMenuItems, meta) : undefined}
                        defaultSelectedKeys={meta ? [meta] : undefined}
                        activeKey={activeKey}
                    ></Menu>
                </Sider>
                {/* <Layout style={{ padding: '24px' }}> */}
                <Layout style={{ paddingLeft: '10px' }}>
                    <Content
                        className={!isShowToolbar ? 'hide_editor_toolbar' : 'show_editor_toolbar'}
                        style={{
                            background: '#fff',
                            padding: 10,
                            margin: 0,
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Loader status={loading}>
                            <Flex justify="space-between">
                                <Title
                                    level={3}
                                    style={{
                                        marginTop: 0,
                                        marginLeft: 10,
                                        marginBottom: !isShowToolbar ? 0 : 15
                                    }}
                                    children={selectedNote?.Name?.[language] ?? selectedNote?.Code}
                                />
                                <Space>
                                    <Button onClick={notes.retry} icon={<ReloadOutlined />} />

                                    {isShowToolbar ? (
                                        mode === 'edit' ? (
                                            <Space.Compact>
                                                <Button
                                                    type="primary"
                                                    onClick={async () => {
                                                        try {
                                                            setBtnLoading(true);
                                                            if (rootMeta === 'InfoMetaNotes') {
                                                                await metaStore.makeSave({
                                                                    meta: 'InfoMeta',
                                                                    objects: [
                                                                        {
                                                                            Id: metaID.value,
                                                                            Code: selectedNote?.Code,
                                                                            Description: {
                                                                                [language]:
                                                                                    selectedNote
                                                                                        ?.Content?.[
                                                                                        language
                                                                                    ]
                                                                            }
                                                                        }
                                                                    ]
                                                                });
                                                            } else if (
                                                                rootMeta === 'InfoReleaseNotes'
                                                            ) {
                                                                await metaStore.makeSave({
                                                                    meta: rootMeta,
                                                                    objects: [
                                                                        selectedNote
                                                                        // {
                                                                        //     Id: selectedNote?.Id,
                                                                        //     Code: selectedNote?.Code,
                                                                        //     Description: {
                                                                        //         [language]:
                                                                        //             selectedNote?.Content?.[language]
                                                                        //     }
                                                                        // }
                                                                    ]
                                                                });
                                                            }
                                                            selectedNoteStartState.current =
                                                                selectedNote;
                                                            setMode('view');
                                                        } catch (error) {
                                                            console.error(error);
                                                            notification.error({
                                                                // key,
                                                                message: t('error'),
                                                                description: (error as Error)
                                                                    .message
                                                            });
                                                        } finally {
                                                            setBtnLoading(false);
                                                        }
                                                    }}
                                                    disabled={
                                                        selectedNoteStartState.current?.Content?.[
                                                            language
                                                        ] === selectedNote?.Content?.[language]
                                                    }
                                                    loading={
                                                        loading || btnLoading || metaID.loading
                                                    }
                                                >
                                                    {t('save')}
                                                </Button>
                                                <Button
                                                    type="default"
                                                    color="danger"
                                                    variant="solid"
                                                    onClick={() => {
                                                        setSelectedNote(
                                                            selectedNoteStartState.current
                                                        );
                                                        setMode('view');
                                                    }}
                                                    // loading={loading || btnLoading || metaID.loading}
                                                >
                                                    {t('cancel')}
                                                </Button>
                                            </Space.Compact>
                                        ) : (
                                            <Button
                                                type="primary"
                                                onClick={() => setMode('edit')}
                                                loading={loading || btnLoading || metaID.loading}
                                            >
                                                {t('edit')}
                                            </Button>
                                        )
                                    ) : null}
                                </Space>
                            </Flex>

                            <MarkdownEditor
                                readOnly={!isPowerUser || (isPowerUser && mode === 'view')}
                                value={selectedNote?.Content?.[language]}
                                meta={selectedNote?.Code}
                                mode={mode}
                                onChange={(newContent) => {
                                    // console.log(newContent);
                                    setSelectedNote((prev) => ({
                                        ...prev,
                                        IsContentExists: isDefined(newContent),
                                        Content: { ...prev.Content, [language]: newContent }
                                    }));
                                }}
                            />
                        </Loader>

                        {/* <Card title={helpContent[selectedKey].title} bordered={false}>
                        {helpContent[selectedKey].content}
                    </Card> */}
                    </Content>
                </Layout>
            </Layout>
        </Loader>
    );
});
