import { AutoComplete, Button, Flex, Input, SelectProps, Space, Typography } from 'antd';
import { toJS } from 'mobx';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { PlainObject } from '@gilbarbara/types';
import { observer } from 'mobx-react-lite';
import { isArray } from 'is-lite/exports';
import { useList, useMedia, useUpdateEffect } from 'react-use';

import { fnv1aHash } from 'smart/utils';
import { springboardStore, useStoreNavigate } from 'utils/store';
import { metaStore } from 'utils/store/MetaStore';

import { MenuItem } from '../../../../../../services/backend-api/generated_api';
import { useAuthUser } from 'modules/client/useAuthUser';
import { LazyIcon } from 'smart/ui';

const Title: React.FC<Readonly<{ title?: string }>> = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
    </Flex>
);

const { Text } = Typography;

type AppSearchOptionType = NonNullable<SelectProps['options']>[number] & {
    group?: 'meta' | 'menu';
    valueForSearch?: string;
    data?: PlainObject<any>;
};

interface AppSearchProps {
    style?: React.CSSProperties;
    className?: string;
    isOnSpringboard?: boolean;
}

const getSortByLanguageAlphabet = (language: string) => (a: any, b: any) =>
    (a.valueForSearch as string)?.localeCompare(
        b.valueForSearch ?? '',
        language === 'tech' ? 'en' : language
    ) ?? 0;

const getParent = (menu: MenuItem, menuMap: Map<string, MenuItem>) => {
    let result = null;

    const parent = menu.parent_id;
    if (parent) {
        result = menuMap.get(parent);

        const parentParent = result?.parent_id;
        if (parentParent) {
            result = getParent(result, menuMap);
        }
    }

    return result;
};

export const AppSearch = observer<AppSearchProps>(({ style, className, isOnSpringboard }) => {
    const {
        t,
        i18n: { language }
    } = useTranslation();
    const storeNavigate = useStoreNavigate();
    const isBigMobile = useMedia('(max-width: 480px)');
    const metaMenus = metaStore.meta.get('all')?.menu;
    const metaMetas = metaStore.meta.get('InfoMeta')?.select?.objects;
    const [search, setSearch] = useState('');
    const { isPowerUser } = useAuthUser();
    const metaMenusMap = useMemo(
        () =>
            (toJS(metaMenus?.items) ?? [])?.reduce((map, menu) => {
                map.set(menu.id, menu);
                return map;
            }, new Map<string, MenuItem>()),
        [metaMenus]
    );

    // const refViewTemplate = useMemo(() => {
    //     return metaMetas?.find((meta) => meta.Code === 'InfoMeta')?.RefViewTemplate;
    // }, [metaMetas]);

    const favorites = toJS(springboardStore.favorites);

    const getAddToFavoritesHandler: (m: PlainObject) => MouseEventHandler = useCallback(
        (m) => (e) => {
            e.stopPropagation();
            springboardStore.pushOrRemove(m);
        },
        []
    );

    const metaOptions: AppSearchOptionType[] = useMemo(
        () =>
            metaMetas
                ?.map((m) => {
                    const id = m?.Id;
                    // console.log(m);

                    const displayValue = m?.DisplayName?.[language];
                    // const displayValue =
                    //     parseTemplate(refViewTemplate || '', language, { self: m }) ||
                    //     m?.PluralName?.[language] ||
                    //     m?.Code;

                    return {
                        key: id,
                        value: id,
                        valueForSearch: displayValue,
                        group: 'meta' as const,
                        data: m,
                        label: (
                            <Flex justify={'space-between'}>
                                <Text style={{ padding: 0 }}>{displayValue}</Text>
                                <Button
                                    size={'small'}
                                    type={'text'}
                                    onClick={getAddToFavoritesHandler(m)}
                                >
                                    {favorites.find((f) => f.Id === m.Id) ? (
                                        <StarFilled style={{ color: 'gold' }} />
                                    ) : (
                                        <StarOutlined />
                                    )}
                                </Button>
                            </Flex>
                        )
                    };
                })
                .sort(getSortByLanguageAlphabet(language)) ?? [],
        [favorites, getAddToFavoritesHandler, language, metaMetas]
    );

    const menuOptions: AppSearchOptionType[] = useMemo(
        () =>
            metaMenus?.items
                .filter((m) => !m.is_hidden && !m.is_folder)
                ?.map((m) => {
                    // console.log(m);

                    const parentMenu = getParent(m, metaMenusMap);

                    // const parent = m.parent_id;
                    // if (parent) {
                    //     parentMenu = metaMenusMap.get(parent);

                    //     const parentParent = parentMenu?.parent_id;
                    //     if (parentParent) {
                    //         parentMenu = metaMenusMap.get(parentParent);
                    //     }
                    // }

                    const parentName = parentMenu?.name?.[language];

                    const name = m.name?.[language];
                    const displayValue = parentName ? `${name} (${parentName})` : name;
                    const id = m.id;

                    return {
                        key: id,
                        value: id,
                        valueForSearch: name,
                        group: 'menu' as const,
                        data: m as unknown as PlainObject,
                        label: (
                            <Flex justify={'space-between'}>
                                <Text style={{ padding: 0 }}>{displayValue}</Text>
                                <Button
                                    size={'small'}
                                    type={'text'}
                                    onClick={getAddToFavoritesHandler(m as unknown as PlainObject)}
                                >
                                    {favorites.find((f) => f.id === m.id) ? (
                                        <StarFilled style={{ color: 'gold' }} />
                                    ) : (
                                        <StarOutlined />
                                    )}
                                </Button>
                            </Flex>
                        )
                    };
                })
                .sort(getSortByLanguageAlphabet(language)) ?? [],
        [favorites, getAddToFavoritesHandler, language, metaMenus?.items, metaMenusMap]
    );

    const initialOptions: AppSearchOptionType[] = useMemo(() => {
        const res = [
            {
                label: <Title title="Menu" />,
                options: menuOptions
            }
        ];

        if (isPowerUser) {
            res.push({
                label: <Title title="Meta" />,
                options: metaOptions
            });
        }

        return res;
    }, [isPowerUser, menuOptions, metaOptions]);

    const [options, optionsMake] = useList<AppSearchOptionType>(initialOptions);
    const [selectedOption, setSelectedOption] = useState<AppSearchOptionType>();

    const handleSearch = useCallback(
        (search: string) => {
            setSearch(search);
            if (search) {
                optionsMake.set(() =>
                    initialOptions.map((o) => ({
                        ...o,
                        options: o.options?.filter((oc: AppSearchOptionType) =>
                            oc.valueForSearch?.toLowerCase()?.includes(search?.toLowerCase())
                        )
                    }))
                );
            } else {
                optionsMake.set(initialOptions);
            }
        },
        [initialOptions, optionsMake]
    );

    const handleClear = () => {
        setSelectedOption(undefined);
        handleSearch('');
    };

    const handleChangeOption = useCallback<
        NonNullable<SelectProps<any, AppSearchOptionType>['onChange']>
    >(
        (value, option) => {
            if (!isArray(option)) {
                if (isOnSpringboard) handleClear();
                else handleSearch(option.valueForSearch as string);

                const data = option.data;
                const group = option.group;

                if (!data) {
                    throw new Error('Please, declare prop "data" for option\'s item');
                }

                if (group === 'meta') {
                    const pathname = `/other/${data.Code}`;
                    const pageTitle = data?.PluralName[language] || data?.Code;
                    const cacheKey = fnv1aHash(`${data.Id}_${pageTitle}`);

                    storeNavigate(
                        { pathname },
                        {
                            state: {
                                cacheKey,
                                pageTitle
                            }
                        }
                    );
                } else if (group === 'menu') {
                    const pageTitle = data.name?.[language];
                    const [pathname, filterString] = (data.path as string).split('?');
                    const cacheKey = fnv1aHash(`${data.id}_${pageTitle}`);

                    const state = filterString
                        ? {
                              filterString,
                              cacheKey,
                              pageTitle
                          }
                        : {
                              cacheKey,
                              pageTitle
                          };

                    storeNavigate({ pathname }, { state });
                }
            }
        },
        [handleSearch, language, storeNavigate]
    );

    useUpdateEffect(() => {
        handleSearch(selectedOption?.valueForSearch ?? search);
    }, [favorites.length]);

    return (
        <Space.Compact style={{ width: '100%', ...style }}>
            <AutoComplete
                popupClassName="certain-category-search-dropdown"
                popupMatchSelectWidth={isBigMobile ? undefined : 400}
                getPopupContainer={() => document.querySelector('.springboard') as HTMLElement}
                virtual={true}
                style={{ width: '100%' }}
                className={className}
                options={options}
                value={search}
                onChange={(_, option) => {
                    setSelectedOption(option);
                }}
                onSearch={handleSearch}
                onSelect={handleChangeOption}
            >
                <Input
                    allowClear
                    onClear={handleClear}
                    size={isBigMobile ? 'large' : 'middle'}
                    placeholder={`${t('search_by_itvectura')}`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    addonAfter={
                        isBigMobile && <SearchOutlined style={{ color: 'rgba(0, 0, 0, .45)' }} />
                    }
                />
            </AutoComplete>
            {!isBigMobile && (
                <Button
                    type="default"
                    size={isBigMobile ? 'large' : 'middle'}
                    style={{ boxShadow: 'none' }}
                    onClick={() => handleSearch(selectedOption?.valueForSearch ?? search)}
                    icon={<SearchOutlined style={{ color: 'rgba(0, 0, 0, .45)' }} />}
                />
            )}
        </Space.Compact>
    );
});
