import { PlainObject } from '@gilbarbara/types';
import merge from 'deepmerge';
import { Button, Flex, Segmented, Tabs } from 'antd';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Meta, MetaField } from 'modules/services/backend-api/generated_info';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncRetry, useWindowSize } from 'react-use';

import { SmartDetailPageChildTabContent } from 'smart/modules/SmartDetailPage/components';
import { FieldsBox } from 'smart/modules/SmartDetailPage/ui';
import { fieldEditRender, fieldRender, JSONSafeParse, removeUndefinedFields } from 'smart/utils';
import { Loader } from 'ui/Loader/Loader';

import { metaStore } from 'utils/store/MetaStore';
import { BuildOutlined, EditOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import { JsonField } from '../JsonField/JsonField';

import './InfoEditor.scss';
import { isEmpty } from 'is-lite/exports';

type InfoLayoutCategory = 'Info' | 'InfoDesktop' | 'InfoMobile';

interface InfoEditorProps {
    meta: string;
    value: { [category: InfoLayoutCategory | string]: PlainObject<any> };
    mode: 'view' | 'edit';
    onChange: (key: string, value: any) => void;
}

const combineMerge = (target: any[], source: any[], options: merge.ArrayMergeOptions) => {
    const destination = target.slice();

    source.forEach((item, index) => {
        const destIndex = destination.findIndex((destItem) => {
            if (destItem.FieldName) return destItem.FieldName === item.FieldName;
            if (destItem.Code) return destItem.Code === item.Code;
            return destItem.Id === item.Id;
        });
        if (destIndex !== -1) {
            destination[destIndex].Id =
                destination[destIndex].Id ?? destination[destIndex].FieldName;
            destination[destIndex] = merge(destination[destIndex], item, options);
        } else {
            destination.push(item);
        }
    });

    if (destination.length === 0 && source.length !== 0) return source;

    return destination;
};

function getDifferences<T extends string>(
    originalArray: PlainObject<T>[],
    modifiedArray: PlainObject<T>[],
    identifier: T
): Partial<PlainObject<T>>[] {
    const result: Partial<PlainObject<T>>[] = [];

    const modifiedMap = new Map(modifiedArray.map((item) => [item[identifier], item]));

    const originalMap = new Map(originalArray.map((item) => [item[identifier], item]));

    // Проверяем все элементы исходного массива
    for (const originalItem of originalArray) {
        const id = originalItem[identifier];
        const modifiedItem = modifiedMap.get(id);

        if (modifiedItem) {
            const differences: Partial<PlainObject<T>> = { [identifier]: id };

            let hasDifference = false;
            for (const key of Object.keys(originalItem)) {
                if (key !== identifier) {
                    const originalValue = originalItem[key];
                    const modifiedValue = modifiedItem[key];

                    if (Array.isArray(originalValue) || Array.isArray(modifiedValue)) {
                        // Обработка массивов
                        if (JSON.stringify(originalValue) !== JSON.stringify(modifiedValue)) {
                            differences[key] = modifiedValue;
                            hasDifference = true;
                        }
                    } else if (JSON.stringify(originalValue) !== JSON.stringify(modifiedValue)) {
                        differences[key] = modifiedValue;
                        hasDifference = true;
                    }
                }
            }

            if (hasDifference) {
                result.push(differences);
            }
        }
    }
    //

    // Проверяем элементы, добавленные в изменённый массив
    for (const modifiedItem of modifiedArray) {
        const id = modifiedItem[identifier];
        if (!originalMap.has(id)) {
            result.push({ [identifier]: id, ...modifiedItem });
        }
    }

    return result;
}

// const originalArray = [
//     { Id: 1, FieldName: 'Key', Name: { ru: 'Ключ', en: 'Key' }, Tags: [1, 2] },
//     { Id: 2, FieldName: 'Code', Name: { ru: 'Код', en: 'Code' } }
// ];

// const modifiedArray = [
//     { Id: 1, FieldName: 'Key', Name: { ru: 'Ключ1', en: 'Key' }, Tags: [2, 3] },
//     { Id: 3, FieldName: 'New', Name: { ru: 'Новый', en: 'New' } }
// ];

// const differences = getDifferences(originalArray, modifiedArray, 'FieldName');

// console.log('#DIFFERENCE#', differences);
/* Вывод:
  [
    { FieldName: 'Key', Name: { ru: 'Ключ1', en: 'Key' }, Tags: [2, 3] },
    { FieldName: 'New', Name: { ru: 'Новый', en: 'New' } }
  ]
  */

export const InfoEditor = observer<InfoEditorProps>(
    ({ meta, value: layoutValue, mode, onChange }) => {
        const {
            t,
            i18n: { language }
        } = useTranslation();
        const { height: windowHeight } = useWindowSize();

        const [userFriendlyMode, setUserFriendlyMode] = useState(true);
        const [tunedInfoCategory, setTunedInfoCategory] = useState<InfoLayoutCategory>('Info');

        console.log('**************', layoutValue);

        const {
            value: model,
            loading: loadingModel,
            error: getModelError,
            retry: refreshModel
        } = useAsyncRetry(async () => {
            const cacheInfo = metaStore.meta.get('InfoMeta')?.info;
            if (!cacheInfo) {
                const res = (await metaStore.getInfo('InfoMeta')) as Meta;
                res.ArrayNameInRoot = 'ChildNodes';
                // console.log('!!!!', res);
                return res;
            }

            cacheInfo.ArrayNameInRoot = 'ChildNodes';
            // console.log('!!!!', toJS(cacheInfo));
            return cacheInfo as Meta;
        }, []);

        const {
            value: fieldsModel,
            loading: loadingFieldsModel,
            error: getFieldsModelError,
            retry: refreshFieldsModel
        } = useAsyncRetry(async () => {
            const cacheInfo = metaStore.meta.get('InfoMetaFields')?.info;
            if (!cacheInfo) {
                const res = await metaStore.getInfo('InfoMetaFields');
                return res as Meta;
            }

            return cacheInfo as Meta;
        }, []);

        const {
            value: data,
            loading: loadingData,
            error: getDataError,
            retry: refreshData
        } = useAsyncRetry(async () => {
            const cacheInfo = metaStore.meta.get(meta)?.info;
            if (!cacheInfo) {
                const res = await metaStore.getInfo(meta);
                return res as Meta;
            }

            return cacheInfo as Meta;
        }, [meta]);

        const value = useMemo(() => {
            const initial = layoutValue ?? {};

            if (data) {
                return {
                    ...initial,
                    [tunedInfoCategory]: merge(
                        toJS(data),
                        removeUndefinedFields(initial[tunedInfoCategory]),
                        {
                            arrayMerge: combineMerge
                        }
                    )
                };
            }

            return { ...initial, Info: undefined };
        }, [data, layoutValue, tunedInfoCategory]);

        const handleChange = useCallback(
            (key: string, newValue: any) => {
                // console.log(key, newValue);
                const initial = layoutValue ?? {};
                const layoutInfo = initial[tunedInfoCategory];

                if (Array.isArray(newValue) && data) {
                    let result;

                    if (key === 'Fields') {
                        result = getDifferences(data[key as keyof Meta], newValue, 'FieldName');
                    } else if (key === 'ChildNodes') {
                        result = getDifferences(data[key as keyof Meta], newValue, 'Code');
                    } else {
                        result = getDifferences(data[key as keyof Meta], newValue, 'Id');
                    }

                    onChange('Info', {
                        ...initial,
                        [tunedInfoCategory]: {
                            ...layoutInfo,
                            [key]: result.length ? result : undefined
                        }
                    });
                } else if (!isEqual(data?.[key as keyof Meta], newValue)) {
                    const result = {
                        ...layoutInfo,
                        [key]: newValue === '' ? undefined : newValue
                    };

                    if (isEmpty(removeUndefinedFields(result))) {
                        onChange('Info', undefined);
                    } else {
                        onChange('Info', {
                            ...initial,
                            [tunedInfoCategory]: result
                        });
                    }
                } else {
                    const result = {
                        ...layoutInfo,
                        [key]: undefined
                    };

                    if (isEmpty(removeUndefinedFields(result))) {
                        onChange('Info', undefined);
                    } else {
                        onChange('Info', {
                            ...initial,
                            [tunedInfoCategory]: result
                        });
                    }
                }
            },
            [data, layoutValue, onChange, value, tunedInfoCategory]
            // [data, layoutValue, onChange, value.Info]
        );

        const handleChangeViewMode = () => {
            setUserFriendlyMode((prev) => !prev);
        };

        // console.log(value);

        const commonInfoFields = useMemo(() => {
            const result = [];

            const fields = model?.Fields ?? [];

            for (const field of fields) {
                const layoutArea = field.LayoutArea;
                const fieldName = field.FieldName;

                // Shouldn't display Main/Audit and Main/Security area's fields
                if (
                    layoutArea === 'Main/Audit' ||
                    layoutArea === 'Main/security' ||
                    layoutArea === 'Page/Header' ||
                    fieldName === 'Code' ||
                    fieldName === 'DbTableName'
                ) {
                    continue;
                }

                const valueType = field.ValueType;
                const options = field.Options;

                const dataSource = value[tunedInfoCategory];
                const fieldData = dataSource?.[fieldName];

                const isInlineJson = // metaSource.FieldName === 'DaysPattern' ||
                    valueType?.includes('json') &&
                    valueType?.includes('display:inline') &&
                    !valueType?.includes('json_type') &&
                    !fieldName.includes('DefaultValue') &&
                    !valueType?.includes('multilang_text');

                result.push({
                    key: fieldName,
                    label: field.Name?.[language] || t(field.ColumnName),
                    vertical: isInlineJson || options?.DescAbove,
                    span: options?.FullWidth ? 2 : 1,
                    description: field.Description?.[language],
                    children:
                        mode === 'edit' ? (
                            <div style={{ width: '100%' }}>
                                {fieldEditRender({
                                    data: fieldData,
                                    onChange: handleChange,
                                    language,
                                    metaFieldData: fields,
                                    fieldName,
                                    dataSource,
                                    rootDataSource: dataSource,
                                    rootMeta: meta
                                })}
                            </div>
                        ) : (
                            fieldRender({
                                data: fieldData,
                                language,
                                metaFieldData: fields,
                                fieldName,
                                dataSource,
                                rootDataSource: dataSource,
                                rootMeta: meta
                            })
                        )
                });
            }

            return result;
            // }, [handleChange, language, meta, mode, model?.Fields, t, value.Info]);
        }, [handleChange, language, meta, mode, model?.Fields, t, value, tunedInfoCategory]);

        // console.log(commonInfoFields);

        const tabs = useMemo(() => {
            return [
                {
                    label: t('common_info'),
                    key: 'common_info',
                    children: <FieldsBox items={commonInfoFields} size={'small'} />
                },
                {
                    label: t('fields'),
                    key: 'fields',
                    children: (
                        <SmartDetailPageChildTabContent
                            data={value[tunedInfoCategory]}
                            setData={function (newData: any): void {}}
                            onChange={handleChange}
                            childNodeMeta={fieldsModel}
                            rootMeta={meta}
                            mode={mode}
                            scroll={{ y: windowHeight - 32 * 14 }}
                        />
                    )
                },
                {
                    label: t('child_nodes'),
                    key: 'child_nodes',
                    children: (
                        <SmartDetailPageChildTabContent
                            data={value[tunedInfoCategory]}
                            setData={function (newData: any): void {}}
                            onChange={handleChange}
                            childNodeMeta={model}
                            rootMeta={meta}
                            mode={mode}
                            scroll={{ y: windowHeight - 32 * 14 }}
                        />
                    )
                }
            ];
        }, [
            commonInfoFields,
            fieldsModel,
            handleChange,
            meta,
            mode,
            model,
            t,
            // value.Info,
            value,
            tunedInfoCategory,
            windowHeight
        ]);

        const loading = loadingModel || loadingFieldsModel || loadingData;

        return (
            <Flex vertical gap={10} style={{ width: '100%' }}>
                <Flex gap={10}>
                    <Button
                        style={{ width: '85px' }}
                        type="default"
                        onClick={handleChangeViewMode}
                        icon={userFriendlyMode ? <EditOutlined /> : <BuildOutlined />}
                        children={userFriendlyMode ? 'JSON' : t('editor')}
                    />

                    {userFriendlyMode && (
                        <Segmented
                            // size={isBigMobile ? 'large' : 'middle'}
                            style={{ width: 'calc(100% - 85px)' }}
                            block
                            id="timeline_table_date_interval"
                            options={[
                                { value: 'Info', label: t('any') },
                                { value: 'InfoDesktop', label: t('desktop') },
                                { value: 'InfoMobile', label: t('mobile') }
                            ]}
                            value={tunedInfoCategory}
                            onChange={setTunedInfoCategory}
                        />
                    )}
                </Flex>

                {userFriendlyMode ? (
                    <Loader status={loading} wrapperClassName="info_editor__loader">
                        <Tabs
                            // onChange={setActiveTab}
                            // activeKey={activeTab}
                            size={'small'}
                            // size={SIZE}
                            style={{ width: '100%' }}
                            type="card"
                            items={tabs}
                        />
                    </Loader>
                ) : (
                    <JsonField
                        jsonValue={
                            typeof layoutValue === 'string'
                                ? JSONSafeParse(layoutValue)
                                : layoutValue
                        }
                        readOnly={mode === 'view'}
                        onChange={(newValue: any) => {
                            onChange('Info', newValue);
                        }}
                        displayMode={'inline'}
                        scriptLanguage="json"
                    />
                )}
            </Flex>
        );
    }
);
