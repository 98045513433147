import { ExclamationCircleOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Flex, Form, Segmented, Tooltip, Typography } from 'antd';
import { Popup } from 'antd-mobile';
import dayjs from 'dayjs';
import { isEmpty } from 'is-lite/exports';
import { capitalize, range } from 'lodash';
import { useAsync, useAsyncRetry, useBoolean, useMap, useMedia, useUpdateEffect } from 'react-use';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, UserSpecificFormat } from 'utils/helpers/dates';
import { Filter } from 'modules/supabase/utils/supabaseClient';
import { SmartMultiSelectField, SmartSelectField, SmartTimeline } from 'smart/components';
import { IObjectWithId, metaStore } from 'utils/store/MetaStore';
import { ReactComponent as TransposeIcon } from 'assets/icons/transpose.svg';

import { PlainObject } from '@gilbarbara/types';
import { Legend } from './components';
import { DATE_FORMAT, DAY_RANGE_ENDPOINTS } from './contants';
import {
    DocTrip,
    RegBookedLocationSlot
} from '../../../modules/services/backend-api/generated_models';
// import { BookedSlotsDataType } from './types';
import './SmartTimelineTablePage.scss';
import { LazyIcon } from '../../ui';
import { Loader } from 'ui/Loader/Loader';

const { Text } = Typography;

// const meta: CODE = 'DocTrips';
//
// const combineArrays = (array1: IObjectWithId[], array2: IObjectWithId[]) => {
//     const combinedArray = array1.concat(array2);
//
//     // Удаление дубликатов по свойству 'id'
//     return combinedArray.filter(
//         (item, index, self) => index === self.findIndex((t) => t.Id === item.Id)
//     );
// };

// const useData = ({
//     locations = [],
//     date,
//     rangeDays,
//     trip
// }: {
//     trip: IObjectWithId | null;
//     locations: IObjectWithId[];
//     date: Dayjs;
//     rangeDays: 1 | 4 | 7;
// }) => {
//     const DST_LOCATION_PLANNING = useAsync(
//         async () =>
//             metaStore.getParam({ param_name: 'DST_LOCATION_PLANNING', default_value: false }),
//         []
//     );
//
//     const minDate = date
//         .set('hours', 0)
//         .set('minutes', 0)
//         .set('seconds', 0)
//         .format(DATE_TIME_FORMAT);
//     const maxDate = date
//         .add(rangeDays - 1, 'days')
//         .set('hours', 23)
//         .set('minutes', 59)
//         .set('seconds', 59)
//         .format(DATE_TIME_FORMAT);
//
//     const fetchData = useCallback(async () => {
//         const filtersOne = [];
//         const filtersTwo = [];
//
//         if (trip) {
//             const tripFilter = `Id=eq.${trip.Id}`;
//             filtersOne.push(tripFilter);
//             filtersTwo.push(tripFilter);
//         }
//
//         if (locations.length > 0) {
//             filtersOne.push(`SourceLocation=in.[${locations.map(({ Id }) => Id).join(',')}]`);
//
//             if (DST_LOCATION_PLANNING.value) {
//                 filtersTwo.push(
//                     `DestinationLocation=in.[${locations.map(({ Id }) => Id).join(',')}]`
//                 );
//             } else {
//                 filtersTwo.push(`SourceLocation=in.[${locations.map(({ Id }) => Id).join(',')}]`);
//             }
//         }
//
//         filtersOne.push(`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`);
//         filtersTwo.push(`EndDateAt=gt.${minDate}&EndDateAt=lt.${maxDate}`);
//
//         if (filtersOne.length || filtersTwo.length) {
//             const responseOne = await metaStore.makeSelect({
//                 meta,
//                 filters: filtersOne.length > 0 ? filtersOne.join('&') : undefined
//             });
//
//             const responseTwo = await metaStore.makeSelect({
//                 meta,
//                 filters: filtersTwo.length > 0 ? filtersTwo.join('&') : undefined
//             });
//
//             if (responseOne.objects && responseTwo.objects) {
//                 return combineArrays(responseOne.objects, responseTwo.objects);
//             }
//         }
//
//         return [];
//     }, [locations, maxDate, minDate, trip, DST_LOCATION_PLANNING]);
//
//     return {
//         ...useAsyncRetry(fetchData, [fetchData]),
//         DST_LOCATION_PLANNING: DST_LOCATION_PLANNING.value
//     };
// };

// const useFields = () => {
//     const fields = useAsync(async () => {
//         const response = await metaStore.getInfo(meta);
//
//         return response?.Fields;
//     });
//
//     return fields.value ?? [];
// };

interface SmartTimelinePageProps {
    fromMeta: string;
    filters?: Filter[];
    fullState?: PlainObject;
}

export const SmartTimelineTablePage = memo<SmartTimelinePageProps>(
    ({ fromMeta, filters, fullState }) => {
        const stateData = fullState?.data;

        const isTripDisabled = fromMeta === 'DocTrips';
        const isResourcesDisabled = fromMeta === 'CatLocationResources';
        const isLocationsDisabled = fromMeta === 'CatLocations';

        const initialLocations =
            stateData?.SourceLocation && fromMeta === 'DocTrips'
                ? [stateData.SourceLocation]
                : stateData && fromMeta === 'CatLocations'
                ? [stateData]
                : [];

        const initialTrips = stateData && fromMeta === 'DocTrips' ? stateData : null;
        const initialResources =
            stateData && fromMeta === 'CatLocationResources' ? [stateData] : [];
        const initialTripDate =
            stateData?.StartDateAt && fromMeta === 'DocTrips'
                ? dayjs(stateData.StartDateAt)
                : dayjs();

        // console.log('1', stateData, initialResources, initialLocations, initialTrips);

        const {
            t,
            i18n: { language }
        } = useTranslation();
        const isBigMobile = useMedia('(max-width: 480px)');

        const [datesRange, setDatesRange] = useState(
            // capitalize((isBigMobile ? t('day') : t('4_days')) as string)
            capitalize(t('day') as string)
        );
        const [displayMode, setDisplayMode] = useState<'horizontal' | 'vertical'>(
            isBigMobile ? 'vertical' : 'horizontal'
        );
        const [date, setDate] = useState(initialTripDate);
        const [resources, setResources] = useState<IObjectWithId[]>(initialResources);
        const [locations, setLocations] = useState<IObjectWithId[]>(initialLocations);
        // console.log(locations);
        const [trip, setTrip] = useState<DocTrip | null>(initialTrips);
        // const [selectedRows, setSelectedRows] = useState<IObjectWithId[]>([]);
        const [legend, legendMake] = useMap<PlainObject>({});
        const [isOpenMobilePopup, setIsOpenMobilePopup] = useBoolean(false);

        // console.log('2', resources, locations, trip);

        const daysCountFromDateRangesEndpoint = useMemo(() => {
            switch (datesRange) {
                case capitalize(t('day') as string):
                    return 1;
                case capitalize(t('4_days') as string):
                    return 4;
                case capitalize(t('week') as string):
                    return 7;
                default:
                    return 1;
            }
        }, [datesRange, t]);

        // если мы совершили переход от рейса - выставляем дату его начала
        useEffect(() => {
            if (trip && isTripDisabled && !stateData) {
                setDate(dayjs(trip.StartDateAt));
            }
        }, [trip, isTripDisabled]);

        // const fields = useFields();
        // const mockTripByFilter = useMemo(
        //     () => ({
        //         Id: filters?.[0].value[0]
        //     }),
        //     [filters]
        // );

        const dateRangeToFetch = useMemo(
            () =>
                range(0, daysCountFromDateRangesEndpoint).map((index) => {
                    return date.add(index, 'days').format(DATE_FORMAT);
                }),
            [date, daysCountFromDateRangesEndpoint]
        );
        const dateIn = dateRangeToFetch.join(',');
        const resourcesIdsIn = resources.map(({ Id }) => Id).join(',');
        const locationsIdsIn = locations.map(({ Id }) => Id).join(',');
        const minDate = useMemo(
            () => date.set('hours', 0).set('minutes', 0).set('seconds', 0).format(DATE_TIME_FORMAT),
            [date]
        );
        const maxDate = useMemo(
            () =>
                date
                    .add(daysCountFromDateRangesEndpoint - 1, 'days')
                    .set('hours', 23)
                    .set('minutes', 59)
                    .set('seconds', 59)
                    .format(DATE_TIME_FORMAT),
            [date, daysCountFromDateRangesEndpoint]
        );

        // console.log('3', dateIn, resourcesIdsIn, locationsIdsIn);

        // const {
        //     value: data,
        //     loading: dataLoading,
        //     error,
        //     retry: fetchData,
        //     DST_LOCATION_PLANNING
        // } = useData({
        //     locations,
        //     date,
        //     rangeDays: daysCountFromDateRangesEndpoint,
        //     trip: fromMeta === 'DocTrips' ? mockTripByFilter : trip
        //     // isFilter: ['DocTrips', 'CatLocationResources', 'CatLocations'].includes(fromMeta)
        // });

        // const locationsFromTripsIds = useMemo(
        //     () => [
        //         ...(data
        //             ?.reduce((acc, item) => {
        //                 const sourceLocationId = item?.SourceLocation?.Id;
        //
        //                 // Проверяем, есть ли такая локация
        //                 if (!acc.has(sourceLocationId)) {
        //                     acc.set(sourceLocationId, item.SourceLocation);
        //                 }
        //
        //                 // учитываем МПЛ Назначения если флаг true
        //                 if (DST_LOCATION_PLANNING) {
        //                     const destinationLocationId = item?.DestinationLocation?.Id;
        //
        //                     if (!acc.has(destinationLocationId)) {
        //                         acc.set(destinationLocationId, item.DestinationLocation);
        //                     }
        //                 }
        //
        //                 return acc;
        //             }, new Map())
        //             .keys() ?? [])
        //     ],
        //     [DST_LOCATION_PLANNING, data]
        // );

        // Запрашиваем рейсы для отбора
        const { loading: tripLoading } = useAsync(async () => {
            if (filters?.[0].value[0] && fromMeta === 'DocTrips' && !trip) {
                // console.log('TRIPS RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'DocTrips',
                    // filters: `Id=eq.${location.state.filters[0].value[0]}`
                    filters: `Id=eq.${filters?.[0].value[0]}`
                });

                if (response) {
                    const trip = response.objects[0];
                    setTrip(trip);
                    const sourceLoc = trip?.SourceLocation;
                    if (!locations.length && sourceLoc) setLocations([sourceLoc]);
                }
            }
        }, [filters, fromMeta]);

        // Запрашиваем МПЛ для отбора
        const { loading: locationsLoading } = useAsync(async () => {
            if (filters?.length && fromMeta === 'CatLocations' && !locations.length) {
                // console.log('LOCATIONS RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'CatLocations',
                    // filters: `Id=in.[${location.state.filters[0].value.join(',')}]`
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setLocations(response.objects);
            }
        }, [filters, fromMeta]);

        // запрашиваем мету ресурсов
        useAsync(async () => {
            await metaStore.getInfo('CatLocationResources');
        }, []);

        // Запрашиваем Ресурсы для отбора
        const { loading: resourcesLoading } = useAsync(async () => {
            if (filters?.length && fromMeta === 'CatLocationResources' && !resources.length) {
                // console.log('RESOURCES RESPONSE');
                const response = await metaStore.makeSelect({
                    meta: 'CatLocationResources',
                    // filters: `Id=in.[${location.state.filters[0].value.join(',')}]`
                    filters: `Id=in.[${filters?.[0].value.join(',')}]`
                });

                if (response) setResources(response.objects);
            }
        }, [filters, fromMeta]);

        // Запрашиваем Свободные слоты
        const freeSlots = useAsyncRetry(async () => {
            if (!locationsIdsIn) return []; // if have not filters - empty

            let filters = `SlotDate=in.[${dateIn}]`;

            if (!isEmpty(resourcesIdsIn)) filters += `&LocationResource=in.[${resourcesIdsIn}]`;
            if (!isEmpty(locationsIdsIn)) filters += `&Location=in.[${locationsIdsIn}]`;
            // else if (!isEmpty(locationsFromTripsIds))
            //     filters += `&Location=in.[${locationsFromTripsIds}]`;

            // console.log('FREE SLOTS RESPONSE');

            const res = await metaStore.makeSelect({
                meta: 'RegLocationResourceSlots',
                filters
            });

            return res?.objects ?? [];
        }, [resourcesIdsIn, locationsIdsIn, dateIn]);
        // }, [resourcesIdsIn, locationsIdsIn, dateIn, locationsFromTripsIds]);

        // Запрашиваем Занятые слоты
        const bookedSlots = useAsyncRetry<RegBookedLocationSlot[]>(async () => {
            if (!locationsIdsIn) return [];

            let filters = `Date=in.[${dateIn}]`;

            if (!isEmpty(resourcesIdsIn)) filters += `&LocationResource=in.[${resourcesIdsIn}]`;
            if (!isEmpty(locationsIdsIn)) filters += `&Location=in.[${locationsIdsIn}]`;
            // else if (!isEmpty(locationsFromTripsIds))
            //     filters += `&Location=in.[${locationsFromTripsIds}]`;

            // console.log('BOOKED SLOTS RESPONSE');
            const res = await metaStore.makeSelect({
                meta: 'RegBookedLocationSlots',
                filters
            });
            return res?.objects ?? [];
        }, [resourcesIdsIn, locationsIdsIn, dateIn]);
        // }, [resourcesIdsIn, locationsIdsIn, dateIn, locationsFromTripsIds]);

        // console.log(bookedSlots);

        useUpdateEffect(() => {
            if (bookedSlots.value) {
                legendMake.setAll(
                    bookedSlots.value.reduce((acc, bs) => {
                        const res = acc;
                        if (bs.Status?.Color) res[bs.Status?.Color] = bs.Status?.Name?.[language];
                        return res;
                    }, {} as PlainObject)
                );
            }
        }, [bookedSlots.value]);

        const fetchBookedSlots = useCallback(
            () => Promise.all([freeSlots.retry(), bookedSlots.retry()]),
            [bookedSlots, freeSlots]
        );

        // const loading = dataLoading || freeSlots.loading || bookedSlots.loading;
        const loading = freeSlots.loading || bookedSlots.loading;

        const reload = async () => {
            await fetchBookedSlots();
            // await Promise.all([fetchData(), fetchBookedSlots()]);
        };

        // const tab1 = useMemo(
        //     () => ({
        //         label: <Text>{t('objects_to_fill')}</Text>,
        //         key: '1',
        //         children: (
        //             <SmartTable
        //                 meta="DocTrips"
        //                 rootMeta="DocTrips"
        //                 fields={fields}
        //                 data={data ?? []}
        //                 loading={dataLoading}
        //                 viewMode="modal"
        //                 onRowSelectionChange={setSelectedRows}
        //                 selectedRows={selectedRows}
        //                 selectable
        //                 // virtual={(data?.length ?? 0) > 29}
        //                 scroll={{
        //                     y: 'calc(100vh - 22rem)'
        //                 }}
        //             />
        //         )
        //     }),
        //     [t, fields, data, dataLoading, selectedRows]
        // );
        //
        // const tab2 = useMemo(
        //     () => ({
        //         label: <Text>{t('resource_workload')}</Text>,
        //         key: '2',
        //         children: (
        //             <SmartTimelineTable
        //                 daysCount={daysCountFromDateRangesEndpoint}
        //                 selectedDate={date}
        //                 selectedResources={resources}
        //                 selectedLocations={locations}
        //                 selectedTrips={trip ? [trip] : selectedRows}
        //                 freeSlots={freeSlots.value ?? []}
        //                 bookedSlots={bookedSlots.value ?? []}
        //                 fetchBookedSlots={fetchBookedSlots}
        //                 loading={freeSlots.loading || bookedSlots.loading}
        //             />
        //         )
        //     }),
        //     [
        //         selectedRows,
        //         t,
        //         daysCountFromDateRangesEndpoint,
        //         date,
        //         resources,
        //         freeSlots,
        //         bookedSlots,
        //         fetchBookedSlots,
        //         locations,
        //         trip
        //     ]
        // );

        return (
            <div id="smart_timeline_table_page_container">
                <Flex align="flex-start" gap={5} wrap="wrap" style={{ marginBottom: 5 }}>
                    <Flex align="center" justify="flex-start" style={{ width: '250px' }}>
                        <Segmented
                            size={isBigMobile ? 'large' : 'middle'}
                            style={{ width: '100%' }}
                            block
                            id="timeline_table_date_interval"
                            options={DAY_RANGE_ENDPOINTS.map((key) => capitalize(t(key) as string))}
                            value={datesRange}
                            onChange={setDatesRange}
                        />
                    </Flex>
                    <Flex gap={5}>
                        <Flex align="center" justify="center" gap={5}>
                            {!isBigMobile && <Text>{t('date')}:</Text>}
                            <DatePicker
                                size={isBigMobile ? 'large' : 'middle'}
                                style={{ width: '150px' }}
                                id="timeline_table_date"
                                value={date}
                                onChange={setDate}
                                format={UserSpecificFormat.getDateFormat()}
                                allowClear={false}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        'smart_timeline_table_page_container'
                                    ) as HTMLElement
                                }
                            />
                        </Flex>
                        <Button
                            size={isBigMobile ? 'large' : 'middle'}
                            icon={<ReloadOutlined />}
                            onClick={reload}
                            disabled={!locationsIdsIn}
                        />
                        <Legend size={isBigMobile ? 'large' : 'middle'} legend={legend} />
                        {!isBigMobile && (
                            <Tooltip
                                title={t('transpose')}
                                placement={'bottom'}
                                destroyTooltipOnHide={true}
                            >
                                <Button
                                    icon={<LazyIcon component={TransposeIcon} />}
                                    onClick={() => {
                                        setDisplayMode((prev) =>
                                            prev === 'horizontal' ? 'vertical' : 'horizontal'
                                        );
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    {!isBigMobile ? (
                        <>
                            <Flex gap={5}>
                                <Text>{t('trip')}:</Text>
                                <div style={{ width: '250px' }}>
                                    <SmartSelectField
                                        meta="DocTrips"
                                        onChange={setTrip}
                                        value={trip}
                                        loading={tripLoading}
                                        disabled={isTripDisabled || !locationsIdsIn}
                                        filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                    />
                                </div>
                            </Flex>

                            <Flex align="center" gap={5}>
                                <Text>
                                    <Text style={{ display: 'inline' }} type="danger">
                                        *
                                    </Text>
                                    {t('locations')}:
                                </Text>
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName="CatLocations"
                                        filters="TotalResourcesCount=gt.0"
                                        onChange={setLocations}
                                        loading={locationsLoading}
                                        value={locations}
                                        isDisabled={isLocationsDisabled || isResourcesDisabled}
                                    />
                                </div>
                            </Flex>
                            <Flex align="center" gap={5}>
                                <Text>{t('resources')}:</Text>
                                <div style={{ width: '250px' }}>
                                    <SmartMultiSelectField
                                        metaName="CatLocationResources"
                                        onChange={setResources}
                                        value={resources}
                                        loading={resourcesLoading}
                                        isDisabled={isResourcesDisabled || !locationsIdsIn}
                                        filters={
                                            locations.length === 0
                                                ? ''
                                                : `Location=in.[${locations
                                                      .map(({ Id }) => Id)
                                                      .join(',')}]`
                                        }
                                    />
                                </div>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Badge
                                size={'large'}
                                dot={!!(trip || locations?.length || resources?.length)}
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                    children={t('filters')}
                                    onClick={() => setIsOpenMobilePopup(true)}
                                    size={'large'}
                                />
                            </Badge>
                            <Popup
                                bodyStyle={{
                                    display: 'flex',
                                    gap: 20,
                                    flexDirection: 'column',
                                    padding: 10
                                }}
                                onClose={() => setIsOpenMobilePopup(false)}
                                onMaskClick={() => setIsOpenMobilePopup(false)}
                                visible={isOpenMobilePopup}
                            >
                                <SmartSelectField
                                    placeholder={t('trip') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    meta="DocTrips"
                                    onChange={setTrip}
                                    value={trip}
                                    loading={loading}
                                    disabled={isTripDisabled}
                                    filters={`StartDateAt=gt.${minDate}&StartDateAt=lt.${maxDate}`} // TODO: дописать. ждем фильтр по OR на бэкенде
                                />

                                <SmartMultiSelectField
                                    placeholder={t('locations') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocations"
                                    onChange={setLocations}
                                    value={locations}
                                    isDisabled={isLocationsDisabled || isResourcesDisabled}
                                />

                                <SmartMultiSelectField
                                    placeholder={t('resources') as string}
                                    style={{ width: 'calc(100% - 20px)' }}
                                    size={'large'}
                                    metaName="CatLocationResources"
                                    onChange={setResources}
                                    value={resources}
                                    isDisabled={isResourcesDisabled}
                                    filters={
                                        locations.length === 0
                                            ? ''
                                            : `Location=in.[${locations
                                                  .map(({ Id }) => Id)
                                                  .join(',')}]`
                                    }
                                />
                            </Popup>
                        </>
                    )}
                </Flex>
                {/* <Tabs defaultActiveKey="1" type="line" items={[tab1, tab2]} /> */}
                <Loader
                    customTip={t('filter_first_message') as string}
                    customIndicator={<ExclamationCircleOutlined />}
                    status={!locationsIdsIn}
                    style={{ color: 'rgba(0,0,0,.85)' }}
                >
                    <SmartTimeline
                        daysCount={daysCountFromDateRangesEndpoint}
                        selectedDate={date}
                        freeSlots={freeSlots.value ?? []}
                        bookedSlots={bookedSlots.value ?? []}
                        selectedResources={resources}
                        selectedLocations={locations}
                        selectedTrips={trip ? [trip] : []}
                        fetchBookedSlots={fetchBookedSlots}
                        loading={freeSlots.loading || bookedSlots.loading}
                        firstColumnFieldName={'LocationResource'}
                        displayMode={displayMode}
                        meta={'CatLocationResources'}
                    />
                </Loader>
            </div>
        );
    }
);
